import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

import plus from 'images/plus.svg';
import minus from 'images/minus.svg';
import Select from './Select';
import { typeWithLabel, statusWithLabel } from './config';
import { formatDateFrom, formatDateTo } from './helpers';

import * as S from './styled';

class Filters extends Component {
  state = {
    isOpened: false,
    dateFrom: null,
    dateTo: null,
    type: typeWithLabel[0].key,
    status: statusWithLabel[0].key,
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.onChange(this.state);
  }

  onSelectorChange = (value, name) => {
    this.setState({ [name]: value }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeDateFrom = (date) => {
    this.setState({ dateFrom: date ? formatDateFrom(date).toISOString() : null }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeDateTo = (date) => {
    this.setState({ dateTo: date ? formatDateTo(date).toISOString() : null }, () => {
      this.props.onChange(this.state);
    });
  }

  toggleFilters = () => {
    this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
  };

  render() {
    const { t, i18n } = this.props;
    const {
      isOpened, type, dateFrom, dateTo, status,
    } = this.state;

    return (
      <S.Expansion expanded={isOpened}>
        <ExpansionPanelSummary onClick={this.toggleFilters}>
          <S.ExpansionSummaryWrapper>
            <Typography>{t('admin.history.filters')}</Typography>
            {isOpened ? (
              <img alt="close" src={minus} />
            ) : (
              <img alt="open" src={plus} />
            )}
          </S.ExpansionSummaryWrapper>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <S.Content>
            <S.Row>
              <S.Column>
                <S.Title>{t('admin.history.filter_by_type')}</S.Title>
                <Select
                  name="type"
                  items={typeWithLabel}
                  selected={type}
                  onChange={(value) => this.onSelectorChange(value, 'type')}
                />
              </S.Column>
              <S.Column>
                <S.Title>{t('admin.history.filter_by_status')}</S.Title>
                <Select
                  name="status"
                  items={statusWithLabel}
                  selected={status}
                  onChange={(value) => this.onSelectorChange(value, 'status')}
                />
              </S.Column>
              <S.Column>
                <S.Title>{t('admin.history.filter_by_date')}</S.Title>
                <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
                  <S.Row>
                    <S.StyledPicker
                      label={t('admin.history.date_from')}
                      value={dateFrom}
                      format="DD/MM/yyyy"
                      onChange={this.handleChangeDateFrom}
                      clearable
                      emptyLabel={t('common.all')}
                      clearLabel={t('common.clear')}
                      cancelLabel={t('common.cancel')}
                      okLabel={t('common.ok')}
                    />
                    <S.StyledPicker
                      label={t('admin.history.date_to')}
                      format="DD/MM/yyyy"
                      value={dateTo}
                      onChange={this.handleChangeDateTo}
                      clearable
                      emptyLabel={t('common.all')}
                      clearLabel={t('common.clear')}
                      cancelLabel={t('common.cancel')}
                      okLabel={t('common.ok')}
                    />
                  </S.Row>
                </MuiPickersUtilsProvider>
              </S.Column>
            </S.Row>
          </S.Content>
        </ExpansionPanelDetails>
      </S.Expansion>
    );
  }
}

export default withTranslation()(Filters);
