import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import Loader from 'components/Loader/index';
import { getMilestoneStatusProperty } from 'helpers/milestonesStatuses';

import TrimmedText from 'components/TrimmedText';
import * as S from './styled';

const prepareDateToShow = (date) => moment(date).format('DD/MM/YY');
const prepareMeetingDateToShow = (date) => moment(date).format('DD MMMM YYYY');

const Body = ({
  rows, isLoading, t, numberRows,
}) => (
  <TableBody>
    {rows.map((row) => (
      <TableRow hover tabIndex={-1} key={row.id}>
        {row.customColumnsData.map((customColumn, index) => {
          if (customColumn.milestoneField === 'meetingDate') {
            return (
              <S.Cell component="th" scope="row">
                {customColumn.value ? prepareMeetingDateToShow(customColumn.value) : '-'}
              </S.Cell>
            );
          }

          if (customColumn.milestoneField === 'startDate' || customColumn.milestoneField === 'completionDate') {
            return (
              <S.Cell component="th" scope="row">
                {customColumn.value ? prepareDateToShow(customColumn.value) : '-'}
              </S.Cell>
            );
          }

          if (customColumn.milestoneField === 'status') {
            return (
              <S.Cell>
                <S.StatusWrapper>
                  <S.StatusImage
                    alt={getMilestoneStatusProperty(row.status, 'text')}
                    src={getMilestoneStatusProperty(row.status, 'icon')}
                    width={20}
                  />
                </S.StatusWrapper>
              </S.Cell>
            );
          }

          return (
            <S.Cell key={row.id + index}>
              <TrimmedText text={customColumn?.value ? customColumn.value : ''} />
            </S.Cell>
          );
        })}
      </TableRow>
    ))}
    {isLoading && (
      <TableRow style={{ height: 49 * 5 }}>
        <S.Cell align="center" colSpan={numberRows}>
          <Loader />
        </S.Cell>
      </TableRow>
    )}
    {!rows.length ? (
      <S.Cell align="center" colSpan={numberRows}>
        {t('admin.milestones_not_found')}
      </S.Cell>
    ) : null}
  </TableBody>
);

Body.propTypes = {
  rows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  numberRows: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Body);
