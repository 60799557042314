import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation } from 'react-i18next';

import * as S from './styled';

const EnhancedTableHead = ({ t, headRows }) => (
  <TableHead>
    <TableRow>
      {headRows.map((row) => (
        <S.Cell
          key={row.key}
        >
          <S.Wrapper>
            {row.name}
          </S.Wrapper>
        </S.Cell>
      ))}
      <S.Cell>
        {t('common.actions')}
      </S.Cell>
    </TableRow>
  </TableHead>
);

EnhancedTableHead.propTypes = {
  t: PropTypes.func.isRequired,
  headRows: PropTypes.array.isRequired,
};

export default withTranslation()(EnhancedTableHead);
