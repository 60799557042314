import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as S from './styled';

const Highlights = ({
  t, indicatorData,
}) => (
  <S.Wrapper>
    <S.StyledPaper>
      <S.Header>
        {indicatorData.highlightsTitle ? indicatorData.highlightsTitle : t('country.highlights') }
      </S.Header>
      <S.Content>
        <S.HighlightContainer key={`${indicatorData.id}-highlights`}>
          {indicatorData.highlights && (
          <S.HighlightText dangerouslySetInnerHTML={{ __html: indicatorData.highlights }} />
          )}
        </S.HighlightContainer>
      </S.Content>

    </S.StyledPaper>
    <S.StyledPaper>
      <S.Header>
        {indicatorData.lowlightsTitle ? indicatorData.lowlightsTitle : t('country.lowlights') }
      </S.Header>
      <S.Content>
        <S.HighlightContainer key={`${indicatorData.id}-lowlights`}>
          {indicatorData.lowlights && <S.HighlightText dangerouslySetInnerHTML={{ __html: indicatorData.lowlights }} />}
        </S.HighlightContainer>
      </S.Content>
    </S.StyledPaper>
  </S.Wrapper>
);

Highlights.propTypes = {
  t: PropTypes.func.isRequired,
  indicatorData: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    getIndicatorHighlightsLogRequest: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({
  indicatorDetails: {
    indicatorData,
  },
}) => ({
  indicatorData,
});

export default connect(mapStateToProps, null)(withTranslation()(Highlights));
