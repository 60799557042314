import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

import { actions as actionsIndicatorDetails } from 'redux/indicatorDetails';
import { getMonthYearFormatDate, getFormatedDateTime } from 'helpers/formatDate';
import { getGoogleMapLink } from 'helpers/getMapLink';
import { updateLinkToLogPage } from 'helpers/navigation';
import scrollToElement from 'helpers/scrollToElement';
import Legend from 'components/Legend';
import Map from './EnhancedMap';
// import Toggle from './Toggle';
import * as S from './styled';

class HocMap extends Component {
  componentDidMount() {
    if (this.props.page === 'map') {
      scrollToElement(this.props.page);
    }
  }

  render() {
    const {
      indicatorId,
      selectedProject,
      lastUpdated,
      t,
      mapData,
      dataSource,
      hasHeatMap,
      target,
    } = this.props;

    return (
      <S.Wrapper id="map">
        <S.SectionTitle>
          {selectedProject.name}{' '}
          <span>
            {`(${getMonthYearFormatDate(selectedProject.updatedAt)})`}
          </span>
        </S.SectionTitle>
        <S.Legend>
          {hasHeatMap && target && (
          <Legend
            values={mapData.statusMilestones}
            isPositive={mapData.isPositiveProgress}
          />
          )}
          <S.LastUpdateNote>
            {`${t('country.updated_at')}  `}
            <S.UpdateLogLink
              to={(location) => updateLinkToLogPage(`${location.pathname}`)}
            >
              {lastUpdated
                ? `${getFormatedDateTime(lastUpdated.time)}`
                : `${getFormatedDateTime(selectedProject.updatedAt)}`}
            </S.UpdateLogLink>
            {` by ${lastUpdated && lastUpdated.name}`}
          </S.LastUpdateNote>
        </S.Legend>
        {/* <Toggle indicatorId={indicatorId} /> */}
        <S.MapContainer>
          <Map
            googleMapURL={getGoogleMapLink()}
            loadingElement={<S.Loading />}
            containerElement={<S.Container />}
            mapElement={<S.MapElement />}
            indicatorId={indicatorId}
          />
        </S.MapContainer>
        <S.SourceNote>
          {`${t('country.source')}: ${dataSource || 'Uknown source'}`}
        </S.SourceNote>
      </S.Wrapper>
    );
  }
}

HocMap.propTypes = {
  indicatorId: PropTypes.string.isRequired,
  lastUpdated: PropTypes.object,
  // country: PropTypes.object.isRequired,
  selectedProject: PropTypes.object.isRequired,
  mapData: PropTypes.object.isRequired,
  page: PropTypes.string,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.string,
  hasHeatMap: PropTypes.bool,
  target: PropTypes.number,
};

HocMap.defaultProps = {
  lastUpdated: undefined,
  page: undefined,
  dataSource: '',
  hasHeatMap: false,
  target: null,
};

const mapStateToProps = ({
  indicatorDetails: { mapData, indicatorData },
  country: { country, selectedProject },
}) => ({
  mapData,
  lastUpdated: mapData.lastUpdated,
  country,
  selectedProject,
  dataSource: indicatorData ? indicatorData.dataSource : '',
  hasHeatMap: indicatorData.hasHeatMap,
  target: indicatorData.target,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionsIndicatorDetails,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HocMap));
