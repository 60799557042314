// read about configurating toolbar https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp

export const toolbarConfig = {
  options: [
    'inline',
    // 'blockType',
    // 'fontSize',
    // 'fontFamily',
    'list',
    // 'textAlign',
    'colorPicker',
    // 'link',
    // 'embedded',
    // 'emoji',
    // 'image',
    // 'remove',
    'history',
  ],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};

export const noop = () => {};
