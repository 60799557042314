import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import TrimmedText from 'components/TrimmedText';
import ActionButtons from './ActionButtons';

import * as S from './styled';

const prepareDateToShow = (date) => moment(date).format('DD/MM/YYYY HH:mm');

const Body = ({
  data,
  t,
}) => (
  <TableBody>
    {data?.map((row) => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <S.Cell component="th" scope="row">
          {prepareDateToShow(row.createdAt)}
        </S.Cell>
        <S.Cell>
          <TrimmedText text={t(`admin.history.filter.${row.type}`)} />
        </S.Cell>
        <S.Cell status={row.status}>
          <TrimmedText text={t(`admin.history.filter.${row.status}`)} />
        </S.Cell>
        <S.Cell>
          <ActionButtons rowId={row.id} />
        </S.Cell>
      </TableRow>
    ))}
  </TableBody>
);

Body.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Body);
