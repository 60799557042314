export const formatDateFrom = (date) => {
  const formattedDate = new Date(date);
  formattedDate.setHours(0);
  formattedDate.setMinutes(0);
  formattedDate.setSeconds(0);
  formattedDate.setMilliseconds(0);

  return formattedDate;
};

export const formatDateTo = (date) => {
  const formattedDate = new Date(date);
  formattedDate.setHours(23);
  formattedDate.setMinutes(59);
  formattedDate.setSeconds(59);
  formattedDate.setMilliseconds(999);

  return formattedDate;
};
