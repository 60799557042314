import React from 'react';
import { Row } from 'fixed-react-data-grid';

import CustomCell from '../CustomCell';

const CustomRow = (props) => (
  <Row
    {...props}
    cellRenderer={(cellProps) => <CustomCell {...cellProps} />}
  />
);

export default CustomRow;
