import React from 'react';
import PropTypes from 'prop-types';

import Body from './Body';
import Header from './Header';
import Pagination from './Pagination';

import * as S from './styled';

const Table = ({
  rowsPerPageOptions,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  page,
  data,
  count,
  t,
}) => (
  <S.Wrapper>
    <S.TableWrapper>
      <S.StyledTable aria-labelledby="tableTitle">
        <Header />
        <Body data={data} t={t} />
      </S.StyledTable>
    </S.TableWrapper>
    <Pagination
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  </S.Wrapper>
);

Table.propTypes = {
  rowsPerPageOptions: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default Table;
