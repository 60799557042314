import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

import ImportButtonCorner from 'modules/Country/Dashboard/ImportButtonCorner';

import { actions as importActions } from 'redux/importIndicatorData';
import { actions as importStatusActions } from 'redux/importIndicatorStatus';
import { actions as updateIndicatorNotesActions } from 'redux/updateIndicatorNotes';
import { checkAccessToNotesUpdate } from 'redux/updateIndicatorNotes/selectors';
import { INDICATOR_STATUSES } from 'helpers/statuses';

import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import Form from './Form/index';
import * as S from './styled';

class ImportIndicatorsGroupData extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isImportStatusModalOpen: PropTypes.bool.isRequired,
    indicator: PropTypes.shape({
      id: PropTypes.number,
      sectionId: PropTypes.number,
      indicatorsGroupId: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      manualStatus: PropTypes.string,
      note: PropTypes.string,
      lowlights: PropTypes.string,
      highlights: PropTypes.string,
      lowlightsTitle: PropTypes.string,
      highlightsTitle: PropTypes.string,
      hasAccessToImport: PropTypes.bool,
      reportingPeriodType: PropTypes.string,
    }).isRequired,
    successMessage: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      closeImportStatusModalState: PropTypes.func.isRequired,
      importIndicatorStatusRequest: PropTypes.func.isRequired,
      updateIndicatorNotesRequest: PropTypes.func.isRequired,
      openImportModalState: PropTypes.func.isRequired,
    }).isRequired,
    requestedYear: PropTypes.number.isRequired,
    countryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    countrySlug: PropTypes.string.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };

  componentDidUpdate(prevProps) {
    const {
      successMessage,
      enqueueSnackbar,
    } = this.props;

    if (successMessage && (prevProps.successMessage !== successMessage)) {
      enqueueSnackbar(successMessage, { variant: 'success' });
      this.handleClose();
    }
  }

  handleSubmit = (values) => {
    const {
      actions,
      indicator: {
        id,
        indicatorsGroupId,
        sectionId,
      },
      countryId,
      countrySlug,
      projectId,
    } = this.props;

    const payload = {
      ...values,
      id,
      countryId,
      countrySlug,
      projectId,

      indicatorsGroupId,
      sectionId,
      note: values.note || '',
      highlights: values.highlights || '',
      lowlights: values.lowlights || '',
      highlightsTitle: values.highlightsTitle || '',
      lowlightsTitle: values.lowlightsTitle || '',
    };

    actions.importIndicatorStatusRequest(payload);
  };

  handleClose = () => {
    const { actions } = this.props;

    actions.closeImportStatusModalState();
  };

  onImport = (e) => {
    const {
      indicator: {
        id,
        reportingPeriodType,
      },
      actions,
    } = this.props;

    e.preventDefault();
    actions.openImportModalState({
      indicator: {
        id,
        reportingPeriodType,
      },
    });
  };

  render() {
    const {
      indicator: {
        id,
        sectionId,
        title,
        manualStatus,
        note,
        highlightsTitle,
        highlights,
        lowlightsTitle,
        lowlights,
        hasAccessToImport,
      },
      requestedYear,
      isLoading,
      isImportStatusModalOpen,
      countrySlug,
      error,
      t,
    } = this.props;

    const initialValues = {
      year: requestedYear,
      status: manualStatus || INDICATOR_STATUSES.automatic.key,
      note,
      highlightsTitle,
      highlights,
      lowlightsTitle,
      lowlights,
    };
    return (
      <Modal
        open={isImportStatusModalOpen}
        onClose={this.handleClose}
      >
        <S.Modal>
          <S.Row>
            <S.Title>{title}</S.Title>
            <div>
              {hasAccessToImport
                && <ImportButtonCorner iconType="publish" onImport={this.onImport} titleType={t('common.import')} />}
              <S.Link
                onClick={this.handleClose}
                to={`/country/${countrySlug}/country-dashboard/section/${sectionId}/editIndicator/${id}`}
              >
                {t('country.edit_data')}
              </S.Link>
            </div>
          </S.Row>
          <S.Loader isLoading={isLoading}>
            <LinearProgress />
          </S.Loader>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            onClose={this.handleClose}
          />
          {error && (
            <FormHelperText error>{error}</FormHelperText>
          )}
        </S.Modal>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  importIndicatorsStatusData: {
    isLoading,
    isImportStatusModalOpen,
    isFormDisabled,
    indicator,
    successMessage,
    error,
  },
  indicatorDetails: {
    year,
  },
  auth,
  country,
}) => ({
  isLoading,
  isImportStatusModalOpen,
  isFormDisabled,
  indicator,
  successMessage,
  error,
  requestedYear: year,
  countrySlug: country?.country?.slug,
  countryId: country?.country?.id,
  projectId: country?.selectedProject?.id,
  hasAccessToNotesUpdate: checkAccessToNotesUpdate(auth),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...importStatusActions,
    ...updateIndicatorNotesActions,
    ...importActions,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar,
  withTranslation(),
)(ImportIndicatorsGroupData);
