import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { MILESTONES_STATUSES } from 'configs/statuses';

import * as S from './styled';

const UpcomingMilestones = ({ data, t }) => (
  <S.StyledPaper>
    <S.Header>
      <S.Title>{t('common.upcoming_milestones')}</S.Title>
    </S.Header>
    <S.Body>
      {data.length
        ? (data.map((item) => (
          <S.MilestoneBlock key={item.id}>
            <S.TitleString>
              <S.StatusText>{`${moment(item.completionDate).format('MMM YYYY')} - ${t('common.status')}: `}</S.StatusText>
              <S.StatusRect color={MILESTONES_STATUSES[item.status].color} />
              <S.StatusString color={MILESTONES_STATUSES[item.status].color}>
                {t(`common.${MILESTONES_STATUSES[item.status].description}`)}
              </S.StatusString>
            </S.TitleString>
            <S.Description>
              {item.name}
            </S.Description>
          </S.MilestoneBlock>
        )))
        : <S.StyledPaper />}
    </S.Body>
  </S.StyledPaper>
);

UpcomingMilestones.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UpcomingMilestones);
