import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ReactDataGrid from 'fixed-react-data-grid';

import CustomRow from './CustomRow';
import * as S from './styled';

class Table extends PureComponent {
  render() {
    const {
      grid,
      columns,
      updateData,
      setSelection,
      getCellActions,
    } = this.props;

    return (
      <S.ReactDataGridWrapper>
        <ReactDataGrid
          rowRenderer={CustomRow}
          columns={columns}
          rowGetter={(i) => grid[i]}
          rowsCount={grid.length}
          enableCellSelect
          onGridRowsUpdated={updateData}
          minHeight={500}
          rowHeight={40}
          cellRangeSelection={{
            onComplete: setSelection,
          }}
          getCellActions={getCellActions}
        />
      </S.ReactDataGridWrapper>
    );
  }
}

Table.propTypes = {
  updateData: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  grid: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired,
  getCellActions: PropTypes.func.isRequired,
};

export default withTranslation()(Table);
