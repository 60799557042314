import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import * as S from './styled';

const GlobalVisibilityRow = ({
  values,
  onVisibilityChange,
  t,
}) => (
  <div>
    <S.Row style={{ padding: '0.5rem 1rem', fontSize: '14px' }}>
      <label style={{ color: '#26323e' }}>{t('admin.global_visibility')}</label>
      <S.Select
        value={values.hasGlobalVisibility}
        onChange={(e) => {
          onVisibilityChange(e.target.value);
        }}
      >
        <option value="no access">No Access</option>
        <option value="admin">Admin</option>
        <option value="manager">Contributer</option>
        <option value="viewer">Viewer</option>
      </S.Select>
    </S.Row>
  </div>
);

GlobalVisibilityRow.propTypes = {
  values: PropTypes.object.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(GlobalVisibilityRow);
