import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const MaterialSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#023870',
    '&$checked': {
      transform: 'translateX(15px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#023870',
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: '1px solid #023870',
    borderRadius: 10,
    height: 'auto',
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {
  },
}))(Switch);

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
`;
