import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import formatInteger from 'helpers/formatInteger';
import { actions as globalActions } from 'redux/global';

import Counts from './Counts';
import * as S from './styled';

class Header extends PureComponent {
  static propTypes = {
    citizens: PropTypes.number,
    countries: PropTypes.number,
    projects: PropTypes.number,
    sections: PropTypes.number,
    indicators: PropTypes.number,
    t: PropTypes.func.isRequired,
    printDashboard: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      getGlobalStatisticRequest: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    citizens: undefined,
    countries: undefined,
    projects: undefined,
    sections: undefined,
    indicators: undefined,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.getGlobalStatisticRequest();
  }

  render() {
    const {
      citizens,
      countries,
      projects,
      sections,
      indicators,
      t,
      printDashboard,
    } = this.props;

    return (
      <S.Wrapper>
        <S.Counts>
          <S.Description>
            <S.SubTitle>What is DA Compass?</S.SubTitle>
            <div style={{ display: 'flex', fontWeight: 'light' }}>
              <p>
                {t('global.description_1')}
              </p>
              <p>
                If you’re looking for help on how to do various tasks such as adding projects, data or users please check out <a href="https://www.da.digital/tutorial-category/1-creating-projects-and-cards">our tutorials</a> or <a href="mailto:iani.zeigerman@deliveryassociates.com">ask us a question</a>.
              </p>
            </div>
          </S.Description>
          <Counts
            countries={countries}
            projects={projects}
            sections={sections}
            indicators={indicators}
          />
        </S.Counts>
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
        }}
        >
          <S.Title>{t('global.global_dashboard')}</S.Title>

          <S.StyledButton handlePrint={printDashboard} />
        </div>

      </S.Wrapper>
    );
  }
}

const mapStateToProps = ({
  global: {
    statistic: {
      citizens,
      countries,
      projects,
      sections,
      indicators,
    },
  },
}) => ({
  citizens,
  countries,
  projects,
  sections,
  indicators,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...globalActions,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(Header);
