import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from 'images/da-compass-logo-dark.svg';

export const StyledAppBar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.countryTheme.colors.mainColor};
    color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
    align-items: center;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    @media print {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      left: 0;
      width: 25%;
      background: #f8f9fb;
      height: 100%;
      z-index: -1;
      border-radius: 0 3rem 3rem 0;
    }
  }
`;

export const StyledLogo = styled.div`
  && {
    width: 248px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 48px;
    background-position: 50%;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    margin-left: 15px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    max-width: ${({ theme }) => theme.layout.contentMaxWidth || null};
    padding: ${({ theme }) => theme.layout.contentPadding};
  }
`;

export const LeftSideContent = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export const RightSideContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const StyledTypography = styled(Typography)`
  && {
    color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
    flex-grow: 1;
  }
`;

export const SLinkContainer = styled.div`
  padding: 0 1rem;
  display: flex;
`;

export const SLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
  display: flex;
  align-items: center;
  margin: 0 20px;
`;

export const SText = styled.span`
  margin-left: 6px;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.countryTheme.colors.fontMainColor};
  line-height: 1.5;
`;

export const StyledLogoLinked = styled.a`
  background: #f8f9fb;
  height: 70px;
  border-radius: 0 3rem 3rem 0;
  display: flex;
  justify-content: center;
  margin-right: 2rem;
`;
