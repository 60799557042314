import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = { regionId: props.value, isOpen: true };
  }

  getValue() {
    return { regionId: this.state.regionId };
  }

  // eslint-disable-next-line class-methods-use-this
  getInputNode() { }

  handleChangeComplete = (selectedValue) => {
    this.setState({
      regionId: selectedValue.target.value,
      isOpen: false,
    }, () => this.props.onCommit(selectedValue.target.value));
  };

  handleClose = () => {
    this.setState({ isOpen: false }, () => this.props.onCommit(this.state.regionId));
  };

  render() {
    const {
      name,
      items,
    } = this.props;
    const { regionId, isOpen } = this.state;

    return (
      <S.Select
        open={isOpen}
        onClose={this.handleClose}
        value={regionId}
        onChange={this.handleChangeComplete}
        input={<S.Input id={`select-${name}`} />}
      >
        {items.map((item) => (
          <S.MenuItem key={item.oldId} value={item.id}>
            {item.name}
          </S.MenuItem>
        ))}
      </S.Select>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onCommit: PropTypes.func.isRequired,
};

export default Select;
