import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { getMilestonesStatusKeys, getMilestoneStatusProperty } from 'helpers/milestonesStatuses';

import * as S from './styled';

const minDate = moment(`${moment().year() - 5}-01-01`);
const maxDate = moment(`${moment().year() + 5}-12-31`);

class Filters extends Component {
  // TODO: Move controls to components
  state = {
    indicatorId: this.props.indicatorGroups[0].indicators[0].id,
    indicatorsGroupId: this.props.indicatorGroups[0].indicators[0].indicatorsGroupId,
    responsiblePartyId: 0,
    status: [
      'not-yet-started',
      'at-risk',
      'seriously-at-risk',
      'in-progress',
      'complete',
    ],
    startDateFrom: null,
    startDateTo: null,
    completionDateFrom: null,
    completionDateTo: null,
    meetingDateTo: null,
    meetingDateFrom: null,
    customColumnsDataFilter: {},
  }

  onChangeDebounced = debounce(() => this.props.onChange(this.state), 1000)

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    responsibleParties: PropTypes.array.isRequired,
    indicatorGroups: PropTypes.array.isRequired,
    customColumns: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.onChange(this.state);
  }

  onSectionChange = (event) => {
    if (event.target.value) {
      this.setState({
        indicatorId: event.target.value.indicatorId,
        indicatorsGroupId: event.target.value.indicatorsGroupId,
      }, () => {
        this.props.onChange(this.state);
      });
    }
  }

  onResponsiblePartyChange = (event) => {
    this.setState({ responsiblePartyId: event.target.value }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeCompletionFrom = (date) => {
    this.setState({ completionDateFrom: date ? new Date(date.toISOString()) : null, completionDateTo: null }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeCompletionTo = (date) => {
    this.setState({ completionDateTo: date ? new Date(date.toISOString()) : null }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeStartFrom = (date) => {
    this.setState({ startDateFrom: date ? new Date(date.toISOString()) : null, startDateTo: null }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeStartTo = (date) => {
    this.setState({ startDateTo: date ? new Date(date.toISOString()) : null }, () => {
      this.props.onChange(this.state);
    });
  }

  getIndicatorGroupsSelectItems = () => {
    const { indicatorGroups } = this.props;

    const items = [];

    indicatorGroups.forEach((section) => {
      section.indicators.forEach((indicator) => {
        items.push((
          <MenuItem
            key={indicator.id}
            value={{ indicatorId: indicator.id, indicatorsGroupId: indicator.indicatorsGroupId }}
          >
            {indicator.title}
          </MenuItem>
        ));
      });
    });

    return items;
  }

  handleStatusCheck = (event) => {
    const statusForChange = event.target.value;

    this.setState((state) => {
      const isCheked = state.status.includes(statusForChange);

      let newStatusesState = [...state.status];
      if (isCheked) {
        newStatusesState = state.status.filter((item) => item !== statusForChange);
      } else {
        newStatusesState.push(statusForChange);
      }

      return { status: newStatusesState };
    }, () => {
      this.props.onChange(this.state);
    });
  }

  renderIndicatorName = (indicatorId) => {
    const { indicatorGroups, t } = this.props;
    if (!indicatorId) {
      return t('admin.choose_indicator');
    }

    const indicators = [];
    indicatorGroups.forEach((section) => {
      indicators.push(...section.indicators);
    });
    return indicators.filter((indicator) => indicator.id === indicatorId)[0].title;
  }

  renderResponsiblePartyName = (responsiblePartyId) => {
    const { responsibleParties, t } = this.props;
    if (!responsiblePartyId) {
      return t('common.all');
    }

    return responsibleParties.filter((responsibleParty) => responsibleParty.id === responsiblePartyId)[0].name;
  }

  handleChangeMeetingDateFrom = (date) => {
    this.setState({ meetingDateFrom: date ? new Date(date.toISOString()) : null, meetingDateTo: null }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeMeetingDateTo = (date) => {
    this.setState({ meetingDateTo: date ? new Date(date.toISOString()) : null }, () => {
      this.props.onChange(this.state);
    });
  }

  onMilestonesChange = (event) => {
    event.persist();

    const searchString = event.target.value;

    this.setState({ milestone: searchString }, this.onChangeDebounced);
  }

  onCustomColumnsChange = (event) => {
    event.persist();

    const { id, value } = event.target;

    this.setState((prevState) => ({
      customColumnsDataFilter: {
        ...prevState.customColumnsDataFilter,
        [id]: value,
      },
    }), this.onChangeDebounced);
  }

  render() {
    const { t, i18n, customColumns } = this.props;

    return (
      <S.Content>
        <S.Row>
          <S.Column>
            <S.Label>{t('admin.choose_indicator')}</S.Label>
            <S.IndicatorSelect
              onChange={this.onSectionChange}
              value={this.state.indicatorId}
              renderValue={this.renderIndicatorName}
              inputProps={{
                id: 'indicator',
              }}
            >
              {this.getIndicatorGroupsSelectItems().map((item) => item)}
            </S.IndicatorSelect>
          </S.Column>
        </S.Row>
        <S.Row>
          <S.Column>
            <S.StyledTextField label={t('common.milestones')} onChange={this.onMilestonesChange} />
          </S.Column>
          <S.Column>
            <S.Label>{t('common.responsible_party')}</S.Label>
            <S.PartySelect
              onChange={this.onResponsiblePartyChange}
              value={this.state.responsiblePartyId}
              renderValue={this.renderResponsiblePartyName}
              inputProps={{
                id: 'responsible-party',
              }}
            >
              <S.PartyItem
                key="all"
                value={0}
              >
                {t('common.all')}
              </S.PartyItem>
              {this.props.responsibleParties.map((party) => (
                <S.PartyItem
                  key={party.id}
                  value={party.id}
                >
                  {party.name}
                </S.PartyItem>
              ))}
            </S.PartySelect>
          </S.Column>
        </S.Row>
        <S.Row>
          <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
            <S.StyledPicker
              variant="dialog"
              views={['year', 'month', 'date']}
              label={t('admin.meeting_date_from')}
              value={this.state.meetingDateFrom}
              onChange={this.handleChangeMeetingDateFrom}
              clearable
              minDate={minDate}
              maxDate={maxDate}
              emptyLabel={t('common.all')}
              clearLabel={t('common.clear')}
              cancelLabel={t('common.cancel')}
              okLabel={t('common.ok')}
              allowKeyboardControl
            />
            <S.StyledPicker
              variant="dialog"
              disabled={!this.state.meetingDateFrom}
              views={['year', 'month', 'date']}
              label={t('admin.meeting_date_to')}
              value={this.state.meetingDateTo}
              onChange={this.handleChangeMeetingDateTo}
              minDate={this.state.meetingDateFrom}
              maxDate={maxDate}
              clearable
              emptyLabel={t('common.all')}
              clearLabel={t('common.clear')}
              cancelLabel={t('common.cancel')}
              okLabel={t('common.ok')}
              allowKeyboardControl
            />
            <S.StyledPicker
              variant="dialog"
              views={['year', 'month', 'date']}
              label={t('admin.start_from')}
              value={this.state.startDateFrom}
              onChange={this.handleChangeStartFrom}
              clearable
              minDate={minDate}
              maxDate={maxDate}
              emptyLabel={t('common.all')}
              clearLabel={t('common.clear')}
              cancelLabel={t('common.cancel')}
              okLabel={t('common.ok')}
              allowKeyboardControl
            />
            <S.StyledPicker
              variant="dialog"
              disabled={!this.state.startDateFrom}
              views={['year', 'month', 'date']}
              label={t('admin.start_to')}
              value={this.state.startDateTo}
              onChange={this.handleChangeStartTo}
              minDate={this.state.startDateFrom}
              maxDate={maxDate}
              clearable
              emptyLabel={t('common.all')}
              clearLabel={t('common.clear')}
              cancelLabel={t('common.cancel')}
              okLabel={t('common.ok')}
              allowKeyboardControl
            />
          </MuiPickersUtilsProvider>
        </S.Row>
        <S.Row>
          <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
            <S.StyledPicker
              variant="dialog"
              views={['year', 'month', 'date']}
              label={t('admin.completion_from')}
              value={this.state.completionDateFrom}
              onChange={this.handleChangeCompletionFrom}
              clearable
              minDate={minDate}
              maxDate={maxDate}
              emptyLabel={t('common.all')}
              clearLabel={t('common.clear')}
              cancelLabel={t('common.cancel')}
              okLabel={t('common.ok')}
              allowKeyboardControl
            />
            <S.StyledPicker
              variant="dialog"
              disabled={!this.state.completionDateFrom}
              views={['year', 'month', 'date']}
              label={t('admin.completion_to')}
              value={this.state.completionDateTo}
              onChange={this.handleChangeCompletionTo}
              minDate={this.state.completionDateFrom}
              maxDate={maxDate}
              clearable
              emptyLabel={t('common.all')}
              clearLabel={t('common.clear')}
              cancelLabel={t('common.cancel')}
              okLabel={t('common.ok')}
              allowKeyboardControl
            />
          </MuiPickersUtilsProvider>
          <S.CheckboxBlock>
            <S.Label>{t('common.status')}</S.Label>
            <S.CheckboxGroup>
              {getMilestonesStatusKeys().map((statusKey) => (
                <Checkbox
                  key={statusKey}
                  value={statusKey}
                  checked={this.state.status.includes(statusKey)}
                  checkedIcon={(
                    <img
                      width={30}
                      alt={getMilestoneStatusProperty(statusKey, 'text')}
                      src={getMilestoneStatusProperty(statusKey, 'icon')}
                    />
                  )}
                  classes={{ checked: 'checkedStatus' }}
                  icon={(
                    <img
                      width={30}
                      alt={getMilestoneStatusProperty(statusKey, 'text')}
                      src={getMilestoneStatusProperty(statusKey, 'icon')}
                    />
                  )}
                  onChange={this.handleStatusCheck}
                />
              ))}
            </S.CheckboxGroup>
          </S.CheckboxBlock>
        </S.Row>
        <S.Row>
          {customColumns?.map((column, index) => column.isFilterable
          && (
            <S.Column key={column.id + index}>
              <S.StyledTextField label={column.name} id={column.id} onChange={this.onCustomColumnsChange} />
            </S.Column>
          ))}
        </S.Row>
      </S.Content>
    );
  }
}

export default withTranslation()(Filters);
