import React from 'react';
import PropTypes from 'prop-types';

import * as S from '../styled';

const CustomCell = (props) => {
  const isDisable = props.value === '' && props.column.key !== 'delete';

  return <S.Field {...props} isDisable={isDisable} />;
};

CustomCell.propTypes = PropTypes.object.isRequired;

export default CustomCell;
