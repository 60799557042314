import styled from 'styled-components';
import { Box } from '@rebass/grid';

export const ChartContainer = styled(Box).attrs({
})`
  height: 100%;
  display: flex;
  align-items: flex-start;
`;

export const Container = styled.div`
  flex-grow: 1;
`;
