import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Table } from '@material-ui/core';

import Add from 'images/add.svg';

export const Modal = styled(Paper)`
  && {
    background-color: ${({ theme }) => theme.palette.background.paper};
    box-shadow: ${({ theme }) => theme.shadows[5]};
    left: 50%;
    outline: none;
    overflow-y: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 750px;
    height: 750px;

    @media screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Header = styled.div`
  padding: 25px 25px 0;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
  display: flex;
`;

export const DefaultStylesButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.appBackground};
    color: ${({ theme }) => theme.colors.primaryDarkBlue};
    line-height: 1.5;
    letter-spacing: 0.6px;
    font-size: 14px;
    font-weight: 600;

    :hover {
      border: solid 1px ${({ theme }) => theme.colors.primaryDarkBlue};
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.appBackground};
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
                  0px 4px 5px 0px rgb(0 0 0 / 14%),
                  0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
  }
`;

export const CloseButton = styled(DefaultStylesButton)`
  && {
    margin: 0 25px 40px;
  }
`;

export const SaveButton = styled(CloseButton)`
`;

export const AddButton = styled(DefaultStylesButton)`
  && {
    height: 30px;
    width: ${({ width }) => width}px;
    background-color: ${({ theme }) => theme.colors.primaryDarkBlue};
    color: ${({ theme }) => theme.colors.primaryWhite};
    letter-spacing: 0.3px;
    line-height: 23px;
    border-radius: 5px;
    text-transform: none;
    margin-bottom: 29px;

    :hover {
      background-color: rgb(27, 35, 43);
    }
  }
`;

export const AddIcon = styled.img.attrs(() => ({
  src: Add,
}))`
  margin-right: 7px;
`;

export const StyledTable = styled(Table).attrs((props) => ({
  'aria-labelledby': props['aria-labelledby'] || '',
}))`
  && {
    min-width: 750px;
    padding: 0 25px;
    margin-bottom: 20px;
  }
`;
