import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

export const Cell = styled(TableCell).attrs(() => ({

}))`
  &.MuiTableCell-root {
    font-size: 0.75rem;
    padding: 14px 16px 14px 12px;
    line-height: 1.1rem;
  }
`;

export const blank = styled.div``;
