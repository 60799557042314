export const userRoles = ['super_admin', 'admin', 'manager', 'viewer'];

export const showedUserRoleMap = {
  super_admin: 'SuperAdmin',
  admin: 'Admin',
  manager: 'Manager',
  viewer: 'Viewer',
};

export const filterRoles = (role, rolesMap) => Object.keys(rolesMap)
  .filter((roleItem) => (role === 'super_admin' ? true : roleItem !== 'super_admin'));

export const getCountryRole = (user, countryId) => {
  if (user.role == 'super_admin') { return true; }

  return user.permissions.countries[countryId];
};

export const isCountryAdminOn = (user, countryId) => {
  if (user.role == 'super_admin') { return true; }

  return !!(user.permissions.countries[countryId] && user.permissions.countries[countryId] === 'admin');
};

export const isCountryManagerOn = (user, countryId) => {
  if (user.role == 'super_admin') { return true; }

  return !!(user.permissions.countries[countryId] && user.permissions.countries[countryId] === 'manager');
};

export const isCountryViewerOn = (user, countryId) => !!(user.permissions.countries[countryId] && user.permissions.countries[countryId] === 'viewer');

export const isProjectAdminOn = (user, projectId) => {
  if (user.role == 'super_admin') { return true; }

  return !!(user.permissions.projects[projectId] && user.permissions.projects[projectId] === 'admin');
};

export const isProjectManagerOn = (user, projectId) => {
  if (user.role == 'super_admin') { return true; }

  return !!(user.permissions.projects[projectId] && user.permissions.projects[projectId] === 'manager');
};

export const isProjectViewerOn = (user, projectId) => {
  if (user.role == 'super_admin') { return true; }

  return !!(user.permissions.projects[projectId] && user.permissions.projects[projectId] === 'viewer');
};

export const canManageUsers = (user) => {
  if (user.role == 'super_admin') { return true; }

  const haveProjectsToManage = Object.keys(user.permissions.projects)
    .filter((k) => user.permissions.projects[k] == 'admin' || user.permissions.projects[k] == 'manager').length;

  const haveCountriesToManage = Object.keys(user.permissions.countries)
    .filter((k) => user.permissions.countries[k] == 'admin' || user.permissions.countries[k] == 'manager').length;

  return haveProjectsToManage || haveCountriesToManage;
};

export const canManageProjects = (user) => {
  if (user.role == 'super_admin') { return true; }

  const haveProjectsToManage = Object.keys(user.permissions.projects)
    .filter((k) => user.permissions.projects[k] == 'admin' || user.permissions.projects[k] == 'manager').length;

  const haveCountriesToManage = Object.keys(user.permissions.countries)
    .filter((k) => user.permissions.countries[k] == 'admin' || user.permissions.countries[k] == 'manager').length;

  return haveProjectsToManage || haveCountriesToManage;
};

export const canManageCountryProjects = (user, countryId) => {
  if (user.role == 'super_admin') { return true; }

  return user.permissions.countries[countryId]
    && (user.permissions.countries[countryId] == 'manager' || user.permissions.countries[countryId] == 'admin');
};

export const canManageProject = (user, projectId) => {
  if (user.role == 'super_admin') { return true; }

  return user.permissions.projects[projectId]
    && (user.permissions.projects[projectId] == 'manager' || user.permissions.projects[projectId] == 'admin');
};

export const isSuperAdmin = (user) => user.role === 'super_admin';

export const areUsersHaveSameCountryRole = (user1, user2, countryId) => user1.permissions.countries[countryId] == user2.permissions.countries[countryId];

export const isCurrentUserHasHigherCountryRole = (currentUser, user, countryId) => {
  const roles = ['admin', 'manager', 'viewer'];
  const x = roles.indexOf(currentUser.permissions.countries[countryId]);
  const y = roles.indexOf(user.permissions.countries[countryId]);
  return x >= 0 && x < y;
};

export const areUsersHaveSameProjectRole = (user1, user2, projectId) => user1.permissions.projects[projectId] == user2.permissions.projects[projectId];

export const isCurrentUserHasHigherProjectRole = (currentUser, user, projectId) => {
  const roles = ['admin', 'manager', 'viewer'];
  const x = roles.indexOf(currentUser.permissions.projects[projectId]);
  const y = roles.indexOf(user.permissions.projects[projectId]);
  return x >= 0 && x < y;
};
