import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

export const StyledTableCell = styled(TableCell).attrs((props) => ({
  align: props.align || 'inherit',
}))`
  && {
    color: ${({ color }) => color};
  }
`;

export const Cell = styled(TableCell).attrs(() => ({

}))`
  &.MuiTableCell-root {
    font-size: 0.75rem;
    padding: 14px 16px 14px 12px;
    color: ${({ status, theme }) => {
    if (status) {
      return status === 'error'
        ? theme.colors.red
        : theme.colors.statusGreen;
    }

    return theme.colors.primaryDark;
  }};
  }

  &:nth-child(8) {
    min-width: 60px;
    padding: 6px 12px 6px 12px;
    width: 60px;
  }

  &:nth-child(9) {
    min-width: 56px;
    padding: 6px 32px 6px 16px;
    width: 56px;
  }
`;

export default StyledTableCell;
