import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormHelperText from '@material-ui/core/FormHelperText';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import i18n from 'locales';

import { toolbarConfig } from './config';
import * as S from './styled';

const LOCALES_DICTIONARY = {
  'en-US': 'en',
};

const getInitialEditorState = (initialValue) => EditorState
  .createWithContent(
    ContentState.createFromBlockArray(htmlToDraft(initialValue)),
  );

class Wysiwyg extends Component {
  state = {
    editorState: getInitialEditorState(this.props.value),
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  render() {
    const { editorState } = this.state;
    const { meta } = this.props;

    return (
      <S.Container>
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={toolbarConfig}
          localization={{
            locale: LOCALES_DICTIONARY[i18n.language] || i18n.language,
          }}
        />
        {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
      </S.Container>
    );
  }
}

export default Wysiwyg;
