import { INDICATOR_STATUSES } from 'helpers/statuses';

export const CUSTOM_FIELD_KEY_PREFIX = 'CUSTOM_FIELD_';

export const formatFields = (indicator) => {
  const payload = { ...indicator };

  delete payload.deletedAt;
  delete payload.createdAt;
  delete payload.updatedAt;

  delete payload.indicatorsSettings;
  delete payload.countryId;
  delete payload.projectId;
  delete payload.sectionId;
  delete payload.indicatorsGroupId;
  delete payload.order;
  delete payload.id;
  delete payload.customFields;
  delete payload.dataCount;
  delete payload.note;
  delete payload.highlightsTitle;
  delete payload.highlights;
  delete payload.lowlightsTitle;
  delete payload.lowlights;
  delete payload.dataSource;

  return payload;
};

export const formatDataTable = (data) => {
  const newFields = data.fields.map((field) => {
    if (field?.isCustom) {
      return ({
        name: field.title,
        key: `${CUSTOM_FIELD_KEY_PREFIX}${field.key}`,
        isCustom: field?.isCustom,
        editable: true,
      });
    }

    return ({
      name: field.title,
      key: field.key,
      editable: true,
    });
  });

  const newRegions = data.regions.map((region) => ({
    ...region,
    oldId: region.id,
    id: region.code,
    value: region.name,
  }));

  const newData = data.data.map((item) => {
    const regionId = newRegions.filter((i) => item.regionId === i.oldId);
    let newCustomFields = {};
    if (item?.customFields) {
      newCustomFields = Object.keys(item?.customFields).reduce((acc, key) => ({
        ...acc, [`${CUSTOM_FIELD_KEY_PREFIX}${key}`]: item?.customFields[key],
      }), {});
    }

    return { ...item, ...newCustomFields, regionId: regionId?.[0]?.name };
  });

  return {
    ...data,
    data: newData,
    fields: [...newFields, { name: 'Delete', key: 'delete', className: 'delete' }],
    regions: newRegions,
  };
};

export const formatNotesFields = (indicator) => {
  const payload = { ...indicator };

  delete payload.deletedAt;
  delete payload.createdAt;
  delete payload.updatedAt;

  delete payload.requestedYear;
  delete payload.indicatorsSettings;
  delete payload.countrySlug;
  delete payload.countryId;
  delete payload.projectId;
  delete payload.sectionId;
  delete payload.indicatorsGroupId;
  delete payload.order;
  delete payload.id;
  delete payload.customFields;
  delete payload.dataCount;
  delete payload.title;
  delete payload.year;
  delete payload.status;

  return payload;
};

export const formatSettings = (settings) => {
  const payload = { ...settings };

  delete payload.deletedAt;
  delete payload.createdAt;
  delete payload.updatedAt;

  delete payload.id;
  delete payload.indicatorId;
  delete payload.countryId;
  delete payload.projectId;
  delete payload.sectionId;
  delete payload.indicatorsGroupId;

  return payload;
};

export const formatCustomFields = (fields) => {
  const payload = { ...fields };
  delete payload.indicatorId;
  delete payload.countryId;
  delete payload.projectId;
  delete payload.sectionId;
  delete payload.indicatorsGroupId;

  return payload;
};

export const formatOrderFields = (data) => {
  const payload = { ...data };
  delete payload.countryId;
  delete payload.projectId;
  delete payload.sectionId;
  delete payload.indicatorsGroupId;

  return payload;
};

export const formatDetailFields = (details) => {
  const payload = { ...details };

  delete payload.countryId;
  delete payload.projectId;
  delete payload.id;
  delete payload.year;
  delete payload.selector;
  delete payload.selectedPeriod;

  return payload;
};

export const formatColor = (colorData) => {
  const payload = { ...colorData };

  delete payload.id;
  delete payload.indicatorId;
  delete payload.countryId;
  delete payload.projectId;
  delete payload.sectionId;
  delete payload.indicatorsGroupId;

  return payload;
};

export const formatStatus = (statusData) => {
  const payload = { ...statusData };
  payload.status = payload.status !== INDICATOR_STATUSES.automatic.key ? payload.status : null;

  delete payload.id;
  delete payload.countrySlug;
  delete payload.countryId;
  delete payload.projectId;
  delete payload.note;
  delete payload.highlightsTitle;
  delete payload.highlights;
  delete payload.lowlightsTitle;
  delete payload.lowlights;
  delete payload.projectId;
  delete payload.sectionId;
  delete payload.indicatorsGroupId;
  return payload;
};

export const formatChartData = (chartData) => {
  const chartDataCopy = { ...chartData };
  if (chartDataCopy.colorPalette) {
    if (chartDataCopy.data && chartDataCopy.data.length) {
      chartDataCopy.data = chartDataCopy.data.map((item) => {
        const itemCopy = { ...item };
        itemCopy.colorPalette = chartDataCopy.colorPalette;

        return itemCopy;
      });
    }
  }

  return chartDataCopy;
};
