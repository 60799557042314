import styled from 'styled-components';
import { Cell } from 'fixed-react-data-grid';

export const ReactDataGridWrapper = styled.div`
  .react-grid-Container > .react-grid-Main > .react-grid-Grid {
    .react-grid-Header > .react-grid-HeaderRow > div > .react-grid-HeaderCell {
      /* Excess vertical scroll remove */
      box-sizing: border-box;
      padding-left: 6px;
      padding-right: 6px;
    }

    div:nth-child(2) > .react-grid-Viewport > .react-grid-Canvas > div:nth-child(2) {
      /* Bugfix: added extra space after last row to be able to edit last row */
      margin-bottom: 20px;

      > div:last-child > div {
        border-bottom: none;
      }
    }
  }

  .rdg-cell-action-last {
    float: left;
  }

  .react-grid-Cell {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Field = styled(Cell)`
  background: none;
  box-sizing: border-box;

  .react-grid-Cell__value {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid ${({ isDisable, theme }) => (isDisable ? theme.colors.error : 'none')};
  }
`;

export const Blank = styled.div``;
