import styled from 'styled-components';
import { Table } from '@material-ui/core';
import MuiPaper from '@material-ui/core/Paper';
import { Flex } from '@rebass/grid';

export const StyledTable = styled(Table).attrs((props) => ({
  'aria-labelledby': props['aria-labelledby'] || '',
}))`
  && {
    min-width: 750px;
  }
`;

export const TableWrapper = styled(MuiPaper).attrs(() => ({
  elevation: 3,
}))`
  && {
    min-width: 100%;
  }
`;

export const Wrapper = styled(Flex).attrs(() => ({
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexWrap: 'wrap',
  width: 1,
}))`

`;
