import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 31px;
  margin-bottom: 10px;
`;

export const Blank = styled.div`
`;
