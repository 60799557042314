import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';
import { Link as RouterLink } from 'react-router-dom';

import DACompassLogo from 'images/da-compass-logo-light.svg';

export const Container = styled.div`
  padding: 2rem;
  background: #eef1f5;
  width: 100%;
  height: fit-content;
  border-radius: 5px;
`;

export const Title = styled.h1`
  color: #26323e;
  font-size: 28px;
  margin: 0;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const Text = styled.p`
  padding-right: 1rem;
  flex-grow: 1;
  flex-basis: 0;
`;
export const Button = styled(RouterLink)`
padding: 9px 10px 9px 12px;
border-radius: 4px;
text-decoration: none;
color: white;
background: ${({ theme }) => theme.colors.primaryDark};
font-size: 14px;
height: 32px;
`;
