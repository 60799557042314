import React from 'react';
import Table from './Table';

const Countries = () => (
  <>
    <Table />
  </>
);

export default Countries;
