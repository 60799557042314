import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { canManageUsers, canManageProjects, isSuperAdmin } from 'helpers/roles';
import MenuLink from '../MenuLink';

// import MenuItemCollapsible from '../MenuItemCollapsible';

function SuperAdminNav(props) {
  const {
    navProps,
    t,
    currentUser,
  } = props;

  return (
    <>
      {/* @todo: handle permissions for this */}
      <MenuLink
        {...navProps}
        label={t('global.global_dashboard')}
        path="/global"
        keyItem="globalDashboard"
      />
      {/* <MenuItemCollapsible
        {...navProps}
        label={t('admin.country_dashboard')}
        keyItem="countryDashboard"
        items={allowedCountries.map((country) => ({
          label: country.name,
          path: `/country/${country.slug}`,
          keyItem: country.slug,
        }))}
      /> */}
      {isSuperAdmin(currentUser) && (
        <MenuLink
          {...navProps}
          label={t('admin.manage_countries')}
          path="/admin/countries"
          keyItem="manageCountries"
        />
      )}

      {canManageProjects(currentUser) && (
        <MenuLink
          {...navProps}
          label={t('admin.manage_projects')}
          path="/admin/projects"
          keyItem="manageSections"
        />
      )}

      {canManageUsers(currentUser) && (
        <MenuLink
          {...navProps}
          label={t('admin.manage_users')}
          path="/admin/users"
          keyItem="manageUsers"
        />
      )}

      {isSuperAdmin(currentUser) && (
        <MenuLink
          {...navProps}
          label={t('admin.history.history_API')}
          path="/admin/historyAPI"
          keyItem="historyAPI"
        />
      )}
    </>
  );
}

SuperAdminNav.propTypes = {
  navProps: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SuperAdminNav);
