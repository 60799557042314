import styled from 'styled-components';

export const Container = styled.div`
  width: 550px;
  max-width: 550px;
  min-height: 280px;
  height: 100%;
  overflow: auto;
  border: 1px solid #9e9e9e;
  border-radius: 3px;

  && .rdw-editor-main {
    max-height: 70vh;
  }
`;

export const noop = () => {};
