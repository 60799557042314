import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

class DateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: props.value, isOpen: true };
  }

  getValue() {
    return { date: this.state.date };
  }

  // eslint-disable-next-line class-methods-use-this
  getInputNode() {}

  handleChangeComplete = (selectedDate) => {
    const date = selectedDate.utc().format();

    this.setState({ date, isOpen: false }, () => this.props.onCommit());
  };

  handleClose = () => {
    this.setState({ isOpen: false }, () => this.props.onBlur());
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  render() {
    const { date, isOpen } = this.state;

    return (
      <DatePicker
        open={isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        value={date}
        onChange={this.handleChangeComplete}
        openTo="year"
        format=" "
        views={['year', 'month', 'date']}
      />
    );
  }
}

DateEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onCommit: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default DateEditor;
