import styled from 'styled-components';

import { Flex } from '@rebass/grid';
import { DatePicker } from '@material-ui/pickers';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import TextField from '@material-ui/core/TextField';

export const Content = styled(Flex).attrs(() => ({
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: 1,
}))`

`;

export const Row = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: 1,
}))`
`;

export const StyledPicker = styled(DatePicker).attrs(() => ({
}))`
  && {
    width: 200px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 25px;
`;

export const Title = styled.div`
  color: #212121;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.09px;
  line-height: 29px;
  margin-bottom: 21px;
`;

export const Expansion = styled(ExpansionPanel)`
  && {
    margin-bottom: 34px;
    width: 100%;

    @media print {
      break-inside: avoid;
    }
  }
`;

export const ExpansionSummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 408px;
  }
`;
