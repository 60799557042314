import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import { COLOR_SCALE } from './config';

charts(FusionCharts);

const formatColorScale = (data) => {
  const colorPalette = data.colorPalette !== null
    ? COLOR_SCALE[data.colorPalette]
    : COLOR_SCALE.blue_cyan;
  let missingColorIndex = 0;

  const colorScale = data.data.reduce((acc, item) => {
    if (item.color) {
      acc.push(item.color);
    } else {
      acc.push(colorPalette[missingColorIndex % colorPalette.length]);
      missingColorIndex += 1;
    }

    return acc;
  }, []);

  return colorScale;
};

class FusionStackBarChart extends Component {
  containerRef = React.createRef();

  render() {
    const { data } = this.props;
    const categories = [...new Set(data.originalData.flatMap((datum) => datum.data.map((datum2) => datum2.x)))];
    const dataset = data.data.map((datum) => ({
      seriesname: datum.groupValue,
      data: [...datum.data.map((x) => ({ value: x.y }))],
    }));

    const colorScale = formatColorScale(data);

    const dataSource = {
      chart: {
        plottooltext: '$seriesName: $dataValue',
        theme: 'fusion',
        drawcrossline: '1',
        paletteColors: `${colorScale.map((x) => x)}`,
      },
      categories: [
        {
          category: [...categories.map((x) => ({ label: x }))],
        },
      ],
      dataset,
    };

    console.log({ colorScale });
    return (
      <ReactFusioncharts
        type="stackedcolumn2d"
        width="100%"
        height="400"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    );
  }
}

export default withTranslation()(FusionStackBarChart);
