export const typeWithLabel = [{
  key: 'all',
  text: 'All time',
}, {
  key: 'daily',
  text: 'Daily',
}, {
  key: 'monthly',
  text: 'Monthly',
}];

export const statusWithLabel = [{
  key: 'all',
  text: 'All',
}, {
  key: 'success',
  text: 'Success',
}, {
  key: 'error',
  text: 'Error',
}];
