import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import Add from 'images/add.svg';
import Edit from 'images/edit.svg';

export const Wrapper = styled.div`
  margin-right: 16px;
`;

export const AddButton = styled(Button)`
  && {
    height: 30px;
    padding: ${({ padding }) => padding};
    background-color: ${({ theme }) => theme.colors.primaryDarkBlue};
    color: ${({ theme }) => theme.colors.primaryWhite};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 23px;
    border-radius: 5px;
    text-transform: none;

    :hover {
      background-color: rgb(27, 35, 43);
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
                  0px 4px 5px 0px rgb(0 0 0 / 14%),
                  0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
  }
`;

export const AddIcon = styled.img.attrs(() => ({
  src: Add,
}))`
  margin-right: 7px;
`;

export const EditIcon = styled.img.attrs(() => ({
  src: Edit,
}))`
  width: 13px;
  height: 13px;
  margin-right: 6px;
`;
