import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import { actions as historyApiActions } from 'redux/historyAPI';
import Loader from 'components/Loader';

import Filters from './Filters';
import Table from './Table';
import options from './config';

import * as S from './styled';

class HistoryAPI extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      getHistoryApiRequest: PropTypes.func.isRequired,
    }).isRequired,
    historyApi: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    pagination: {
      page: 0,
      pageCount: 10,
    },
    filters: {},
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getHistoryApiRequest({ ...this.state.filters, ...this.state.pagination });
  }

  handleFiltersChange = (filters) => {
    const { actions } = this.props;

    this.setState({ filters });
    actions.getHistoryApiRequest({
      ...filters,
      ...this.state.pagination,
    });
  }

  handleChangePage = (event, newPage) => {
    const { actions } = this.props;

    this.setState((state) => ({
      pagination: {
        page: newPage,
        pageCount: state.pagination.pageCount,
      },
    }));

    actions.getHistoryApiRequest({
      ...this.state.filters, ...this.state.pagination, page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    const { actions } = this.props;

    this.setState({
      pagination: {
        page: 0,
        pageCount: +event.target.value,
      },
    });

    actions.getHistoryApiRequest({
      ...this.state.filters, page: 0, pageCount: +event.target.value,
    });
  };

  render() {
    const { t, historyApi, isLoading } = this.props;
    const { pagination: { page, pageCount } } = this.state;

    return (
      <S.Container>
        <S.Title>{t('admin.history.history_API_updates')}</S.Title>
        <S.Line />
        <Filters onChange={this.handleFiltersChange} />
        {isLoading ? (
          <Loader isOpen={isLoading} />
        )
          : (
            <Table
              data={historyApi.results}
              rowsPerPageOptions={options.rowsPerPageOptions}
              count={historyApi.total}
              isLoading={isLoading}
              rowsPerPage={pageCount}
              page={page}
              t={t}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
      </S.Container>
    );
  }
}

const mapStateToProps = ({
  historyAPI: {
    historyApi,
    isLoading,
  },
}) => ({
  historyApi,
  isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...historyApiActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HistoryAPI));
