import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation } from 'react-i18next';

import * as S from './styled';

const headRows = [{ id: 1, name: 'time' }, { id: 2, name: 'type' }, { id: 3, name: 'status' }];

const EnhancedTableHead = ({ t }) => (
  <TableHead>
    <TableRow>
      {headRows.map((row) => (
        <S.Cell
          key={row.key}
        >
          <S.Wrapper>
            {t(`admin.history.${row.name}`)}
          </S.Wrapper>
        </S.Cell>
      ))}
      <S.Cell align="center">
        {t('common.actions')}
      </S.Cell>
    </TableRow>
  </TableHead>
);

EnhancedTableHead.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EnhancedTableHead);
