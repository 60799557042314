export const columns = [
  {
    label: 'meeting_date',
    key: 'meetingDate',
    align: 'left',
  },
  {
    label: 'participants',
    key: 'participants',
    align: 'left',
  },
  {
    label: 'milestones',
    key: 'name',
    align: 'left',
  },
  {
    label: 'responsible_party',
    key: 'responsibleParty',
    align: 'left',
  },
  {
    label: 'start_date',
    key: 'startDate',
    align: 'left',
  },
  {
    label: 'completion_target_date',
    key: 'completionDate',
    align: 'left',
  },
  {
    label: 'remarks',
    key: 'remarks',
    align: 'left',
  },
  {
    label: 'status',
    key: 'status',
    align: 'center',
  },
];

export const toggleViewItems = [
  {
    value: 'details',
  },
  {
    value: 'timeline',
  },
];
