import {
  AppBar, Toolbar, Typography, IconButton,
} from '@material-ui/core';
import styled from 'styled-components';

import logoImage from 'images/da-compass-logo-dark.svg';

export const StyledAppBar = styled(AppBar)`
  && {
    background-color: #eef1f5;
    /* stylelint-disable */
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    /* stylelint-enable */
    align-items: center;

    @media print {
      display: none;
    }
    :after {
      content: '';
      position: absolute;
      left: 0;
      width: 25%;
      background: #f8f9fb;
      height: 100%;
      z-index: -1;
      border-radius: 0 3rem 3rem 0;
    }
  }
`;

export const StyledLogo = styled.div`
  && {
    background-image: url(/static/media/da-compass-logo-dark.49fc3ea2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    margin-right: 48px;
    width: 248px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    margin-left: 15px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    max-width: ${({ theme }) => theme.layout.contentMaxWidth || null};
    padding: ${({ theme }) => theme.layout.contentPadding};
  }
`;

export const LeftSideContent = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSideContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const StyledTypography = styled(Typography)`
  && {
    color: #ffffff;
    flex-grow: 1;
  }
`;

export const StyledLogoLinked = styled.a`
  background: #f8f9fb;
  height: 70px;
  border-radius: 0 3rem 3rem 0;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
`;
