const checkHightlightsLowlights = ({
  highlights, lowlights, highlightsTitle, lowlightsTitle,
}) => {
  const paragraph = '<p></p>';
  const isHightLights = ((highlights && highlights !== paragraph) || highlightsTitle);
  const isLowlights = ((lowlights && lowlights !== paragraph) || lowlightsTitle);

  return isHightLights || isLowlights;
};

export default checkHightlightsLowlights;
