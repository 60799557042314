import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import * as S from './styled';

const Select = ({
  name,
  items,
  selected,
  onChange,
  t,
}) => (
  <S.Select
    value={selected}
    onChange={(e) => onChange(e.target.value)}
    input={<S.Input id={`select-${name}`} />}
  >
    {items.map((item) => (
      <S.MenuItem key={item.key} value={item.key}>
        {t(`admin.history.filter.${item.key}`)}
      </S.MenuItem>
    ))}
  </S.Select>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Select);
