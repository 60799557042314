import React from 'react';
import PropTypes from 'prop-types';
import { STATUSES } from 'helpers/statuses';
import { withTranslation } from 'react-i18next';

import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import Label from './Label';
import * as S from './styled';

const getSectorColor = ({ datum }) => (datum.x === 'other'
  ? '#eeeeee'
  : STATUSES[datum.x].color
);

// Resolves charts dependancy
charts(FusionCharts);

const FusionPie = ({ data, isMobileView, t }) => {
  const dataSource = {
    chart: {
      showpercentvalues: '1',
      legendposition: 'bottom',
      showLabels: false,
      showValues: false,
      theme: 'fusion',
      doughnutRadius: 85,
      showLegend: false,
      bgAlpha: '0',
      startingAngle: '90',
      paletteColors: `${data.reverse().map((datum) => `${getSectorColor({ datum })}`)}`,
    },
    data: data.map((d) => ({
      label: t(`common.${d.x}`),
      value: d.y,
    })),
  };

  return (
    <ReactFusioncharts
      type="doughnut2d"
      width="300"
      height="320"
      dataFormat="JSON"
      containerBackgroundOpacity="0"
      dataSource={dataSource}
    />
  );
};

FusionPie.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobileView: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FusionPie);
