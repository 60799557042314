import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eef1f5;
  padding: 0 1rem;
  color: ${({ theme }) => theme.countryTheme.colors.mainColor};

  && {
    .MuiFormControlLabel-label {
      font-weight: normal;
    }
  }
`;

export const Div = styled.div``;

export const Select = styled.select`
  background: #FCFBFB;
  border: 1px solid #DDE3ED;
  padding: 3px;
  border-radius: 4px;
  font-size: 12px;
`;
