import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const FormContentContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  div:first-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Div = styled.div`
  display: flex;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.countryTheme.colors.mainColor};
`;

export const SelectAllButton = styled.div`
  height: 25px;
  color: white;
  border-radius: 4px;
  background: ${({ theme }) => theme.countryTheme.colors.mainColor};

  button {
    color: white;
    text-transform: none;
    padding: 0 8px;
  }
`;

export const Select = styled.select`
  background: #FCFBFB;
  border: 1px solid #DDE3ED;
  padding: 3px;
  border-radius: 4px;
`;

export const StyledButton = styled(Button).attrs(() => ({
}))`
  && {
    color: white;
    background-color: ${({ theme }) => theme.colors.primaryDark};
    border-radius: 4px;
    height: 25px;
    padding-bottom: 0px;
    padding-top: 0px;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    &:hover {
      color: white;
      background-color: ${({ theme }) => theme.colors.primaryDark};
    }

    &:disabled {
      color: white;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
