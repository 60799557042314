import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import getChartColor from 'helpers/getChartColor.js';

import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

class FusionBarChart extends Component {
  containerRef = React.createRef();

  formatData = ({ data }) => data.data.map((d) => ({
    label: d.x,
    value: d.y,
  }))

  render() {
    const { data, getTargetLabel } = this.props;

    const chartConfigs = {
      type: 'column2d',
      width: '100%',
      height: '400',
      dataFormat: 'json',
      dataSource: {
        chart: {
          theme: 'fusion',
          animation: 1,
          showValues: 1,
          paletteColors: data.target ? `${data.data.map((d) => `${getChartColor(d)}`)}` : `${getChartColor(data)}`,
        },
        data: this.formatData({ data }),
        trendlines: data.target ? [{
          line: [
            {
              dashed: '1',
              dashLen: '8',
              dashGap: '4',
              startvalue: data.target.value,
              color: '#c45b1a',
              valueOnRight: '1',
              displayvalue: `${getTargetLabel(data)}`,
            },
          ],
        }] : [],
      },
    };

    return (
      <ReactFC {...chartConfigs} />
    );
  }
}

FusionBarChart.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      y: PropTypes.number.isRequired,
      status: PropTypes.string,
      length: PropTypes.number.isRequired,
    })).isRequired,
    target: PropTypes.shape({
      value: PropTypes.number.isRequired,
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
    valueType: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  domainPadding: PropTypes.func.isRequired,
  getMaxDomain: PropTypes.func.isRequired,
  formatXAxis: PropTypes.func.isRequired,
  getTargetLabel: PropTypes.func.isRequired,
  formatTick: PropTypes.func.isRequired,
  formatVoronoiContainerLabels: PropTypes.func.isRequired,
};

export default withTranslation()(FusionBarChart);
