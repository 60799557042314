import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

import { getCountryRole, isCountryManagerOn, isCountryAdminOn } from 'helpers/roles';
import CountrySelector from '../CountrySelector';
import * as S from './styled';

const EnhancedTableToolbar = ({
  countriesList,
  selectedCountry,
  defaultOption,
  handleSelectCountry,
  onAdd,
  currentUser,
  t,
}) => {
  if (countriesList.length === 1 && !defaultOption) {
    return null;
  }

  return (
    <S.StyledToolbar>
      <CountrySelector
        options={countriesList}
        selectedOption={selectedCountry}
        defaultOption={defaultOption}
        onSelect={handleSelectCountry}
      />

      {/* A manager of a country can create new projects in this country. Any project you create you are an admin for that project. */}
      {
        (
          currentUser.role == 'super_admin'
          || isCountryManagerOn(currentUser, selectedCountry)
          || isCountryAdminOn(currentUser, selectedCountry)
        )
        && (
          <Tooltip title={t('admin.new_project')}>
            <span>
              <S.StyledButton aria-label={t('admin.new_project')} color="primary" onClick={onAdd} disabled={!selectedCountry}>
                {t('admin.new_project')}
              </S.StyledButton>
            </span>
          </Tooltip>
        )
      }
    </S.StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  countriesList: PropTypes.array.isRequired,
  selectedCountry: PropTypes.number,
  defaultOption: PropTypes.number,
  handleSelectCountry: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

EnhancedTableToolbar.defaultProps = {
  defaultOption: undefined,
  selectedCountry: undefined,
};

export default withTranslation()(EnhancedTableToolbar);
