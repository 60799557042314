import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Fab from '@material-ui/core/Fab';
import { withSnackbar } from 'notistack';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import DeleteIcon from '@material-ui/icons/Delete';

import { CUSTOM_FIELD_KEY_PREFIX } from 'http/indicators/formatters';

import { actions as countryActions } from 'redux/country';
import { actions as indicatorsActions } from 'redux/indicators';

import { getYearsRange } from 'helpers/getYears';
import addNewRow from 'images/add-new-row.svg';
import Header from './Header';
import Select from './Select';
import Table from './Table';
import * as S from './styled';

import { formatTableFields } from './helpers';

class EditIndicator extends PureComponent {
  tableWrapper = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      isDisabled: false,
      data: [],
      fields: [],
      dataSource: '',
      language: 'en-US',
      topLeft: {},
      botRight: {},
    };

    document.addEventListener('copy', this.handleCopy);
    document.addEventListener('paste', this.handlePaste);
  }

  componentDidMount() {
    const { match: { params }, actions } = this.props;

    actions.fetchCountryRequest({
      slug: params.country,
    });

    actions.getIndicatorRequest(params);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isLoading,
      successMessage,
      error,
      enqueueSnackbar,
      isUpdateLoading,
      data,
      fields,
      regions,
      t,
      i18n,
      match: { params },
      actions,
      history: { goBack },
    } = this.props;

    if (!isEqual(prevProps.data, data)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data });
    }

    if (prevProps.isLoading && !isLoading && data) {
      const formattedFields = formatTableFields(fields, regions, t);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data, fields: formattedFields });
    }

    if (prevProps.isUpdateLoading !== isUpdateLoading) {
      this.disableSaveButton(isUpdateLoading);
    }

    if (this.state.language !== i18n.language) {
      actions.getIndicatorRequest(params);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ language: i18n.language });
    }

    if (successMessage && (prevProps.successMessage !== successMessage)) {
      enqueueSnackbar(successMessage, { variant: 'success' });
      enqueueSnackbar('Data recalculation may take some time', { variant: 'warning', transitionDuration: 500 });

      setTimeout(() => window.location.replace(`/country/${params.country}/country-dashboard`), 500);
    }

    if (error && (prevProps.error !== error)) {
      if (typeof error === 'string') {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Data format is invalid. Please check table cells.', { variant: 'error' });
        this.applyServerErrors();
      }
    }

    isEqualWith(prevState.data, this.state.data, this.checkRequiredCellAndLatAndLng);
  }

  componentWillUnmount() {
    this.removeAllListeners();
    this.setState({
      isDisabled: false, data: [], fields: [], dataSource: '',
    });
  }

  removeAllListeners = () => {
    document.removeEventListener('copy', this.handleCopy);
    document.removeEventListener('paste', this.handlePaste);
  }

  applyServerErrors = () => {
    this.setState((state, props) => {
      const appliedErrorsData = cloneDeep(state.data);

      props.error.forEach((e) => {
        appliedErrorsData[e.path[0]] = { ...appliedErrorsData[e.path[0]], [e.path[1]]: '' };
      });

      return { data: appliedErrorsData };
    });
  }

  checkRequiredCellAndLatAndLng = (prevState, state) => {
    const { fields } = this.props;

    if (!isEqual(prevState, state) && Array.isArray(state)) {
      const isDisabled = state.some((item) => fields.some((field) => (item[field.key] === '' && field.key !== 'delete')));

      this.disableSaveButton(isDisabled);
    }

    if (prevState?.regionId !== state?.regionId) {
      const { data } = this.state;
      const { regions } = this.props;

      const region = regions.filter((row) => row.id === state?.regionId);
      const newRow = { ...state, lat: region[0].lat.toFixed(2), lng: region[0].lng.toFixed(2) };

      const newData = data.map((row) => {
        if (row.id === newRow.id) {
          return newRow;
        }

        return row;
      });

      this.setState({ data: newData });
    }
  }

  handlePeriodChange = (value) => {
    const { actions, match: { params } } = this.props;

    actions.getIndicatorRequest({ ...params, year: value });

    this.setState({ year: value });
  }

  deleteActions = (rowId) => [
    {
      icon: <DeleteIcon color="inherit" />,
      callback: () => {
        const { data } = this.state;
        this.setState({ data: data.filter((row) => row.id !== rowId) });
      },
    },
  ];

  defaultParsePaste = (str) => (
    str.split(/\r\n|\n|\r/)
      .map((row) => row.split('\t'))
  )

  gridRowsUpdated = ({
    action, fromRow, toRow, updated,
  }) => {
    const { data } = this.state;

    if (action !== 'COPY_PASTE') {
      const newData = data.slice();

      for (let i = fromRow; i <= toRow; i += 1) {
        newData[i] = { ...newData[i], ...updated };
      }

      this.setState({ data: newData });
    }
  }

  updateRows = (startIdx, newRows) => {
    this.setState((state) => {
      const data = state.data.slice();
      for (let i = 0; i < newRows.length; i += 1) {
        if (startIdx + i < data.length) {
          data[startIdx + i] = { ...data[startIdx + i], ...newRows[i] };
        }
      }
      return { data };
    });
  }

  getCellActions = (column, row) => {
    const cellActions = {
      delete: this.deleteActions(row.id),
    };

    return cellActions[column.key];
  }

  handleCopy = (e) => {
    e.preventDefault();
    const { topLeft, botRight, fields } = this.state;

    // Loop through each row
    const text = range(topLeft.rowIdx, botRight.rowIdx + 1).map(
      // Loop through each column
      (rowIdx) => fields.slice(topLeft.colIdx, botRight.colIdx + 1).map(
        // Grab the row values and make a text string
        (col) => this.rowGetter(rowIdx)[col.key],
      ).join('\t'),
    ).join('\n');
    e.clipboardData.setData('text/plain', text);
  }

  handlePaste = (e) => {
    e.preventDefault();
    const { topLeft, fields } = this.state;

    const newRows = [];
    const pasteData = this.defaultParsePaste(e.clipboardData.getData('text/plain'));

    pasteData.forEach((row) => {
      const rowData = {};
      // Merge the values from pasting and the keys from the columns
      fields.slice(topLeft.colIdx, topLeft.colIdx + row.length)
        .forEach((col, j) => {
          if (col.key !== 'regionId') {
            rowData[col.key] = row[j];
          }
          // Create the key-value pair for the row
        });
      // Push the new row to the changes
      newRows.push(rowData);
    });

    this.updateRows(topLeft.rowIdx, newRows);
  }

  rowGetter = (i) => {
    const { data } = this.state;
    return data[i];
  }

  setSelection = (args) => {
    this.setState({
      topLeft: {
        rowIdx: args.topLeft.rowIdx,
        colIdx: args.topLeft.idx,
      },
      botRight: {
        rowIdx: args.bottomRight.rowIdx,
        colIdx: args.bottomRight.idx,
      },
    });
  };

  addNewRow = () => {
    const { data, fields } = this.state;
    const {
      regions,
    } = this.props;

    const timestamp = Date.now();

    const newRow = {};

    fields.forEach((field) => {
      switch (field.key) {
        case ('lat'):
        case ('lng'):
          newRow[field.key] = regions[0][field.key].toFixed(2);
          break;
        case 'date':
          newRow[field.key] = new Date();
          break;
        case 'regionId':
          newRow[field.key] = regions[0].id;
          break;
        case ('year'):
          newRow[field.key] = this.state.year;
          break;
        default:
          newRow[field.key] = '';
      }
    });

    this.setState({ data: [...data, { ...newRow, id: timestamp }] });

    setTimeout(() => {
      this.tableWrapper.scrollIntoView(false);
    }, 0);
  }

  updateSimpleData = (data) => this.setState({ data });

  disableSaveButton = (bool) => this.setState({ isDisabled: bool });

  checkRegion = () => {
    const { regions, fields } = this.props;

    const result = fields?.some((item) => item?.key === 'regionId');

    if (result && regions?.length === 0) {
      return false;
    }
    return true;
  }

  handleSave = () => {
    const {
      actions,
      regions,
      fields,
      match: { params },
    } = this.props;
    const {
      data,
      year,
      dataSource,
    } = this.state;

    const newData = data.map((item) => {
      let newItem = {};
      let customFields = {};
      const customKeyArr = [];

      fields.forEach((field) => {
        if (field.isCustom) {
          customKeyArr.push(field.key);
          const [, fieldKey] = field.key.split(CUSTOM_FIELD_KEY_PREFIX);
          customFields = { ...customFields, [fieldKey || field.key]: item[field.key] };
        } else {
          newItem = { ...newItem, [field.key]: item[field.key] };
        }
      });

      delete newItem.delete;

      if (customKeyArr.some((customKey) => item[customKey])) {
        newItem = { ...newItem, customFields };
      }

      if (item.regionId) {
        const regionId = regions.filter((i) => item.regionId === i.code || item.regionId === i.name);
        newItem = { ...newItem, regionId: regionId[0].oldId };
      }

      return newItem;
    });

    actions.updateIndicatorDataRequest({
      dataSource,
      data: newData,
      ...params,
      year,
    });
  }

  onDataSourceChange = (e) => {
    const dataSource = e.target.value;

    this.setState({ dataSource });
  }

  render() {
    const {
      history: { goBack },
      reportingPeriodType,
      t,
    } = this.props;
    const {
      year,
      data,
      isDisabled,
      fields,
    } = this.state;

    return (
      <S.Wrapper>
        <Header onClickBack={() => goBack()} title={t('country.back_to_indicators')} />
        {reportingPeriodType === 'year' && (
          <S.Select>
            <Select
              title={t('country.choose_reporting_year')}
              name="year"
              items={getYearsRange()}
              selected={year}
              onChange={this.handlePeriodChange}
              fullWidth={false}
            />
          </S.Select>
        )}
        <S.TextField
          onChange={this.onDataSourceChange}
          value={this.state.dataSource}
          label={t('country.data_source')}
          margin="none"
          placeholder={t('country.data_source')}
        />
        {this.checkRegion() ? (
          <>
            <S.Table ref={(el) => { this.tableWrapper = el; }}>
              {data.length > 0 && (
                <Table
                  grid={data}
                  columns={fields}
                  deleteRow={this.deleteRow}
                  updateData={this.gridRowsUpdated}
                  setSelection={this.setSelection}
                  disableSaveButton={this.disableSaveButton}
                  getCellActions={this.getCellActions}
                />
              )}
            </S.Table>
            <S.FabWrapper>
              <S.AddNewRow
                addNewRow={addNewRow}
                onClick={this.addNewRow}
              />
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="save"
                onClick={this.handleSave}
                disabled={isDisabled}
              >
                <SaveOutlinedIcon />
              </Fab>
            </S.FabWrapper>
          </>
        )
          : <S.RegionError>{t('country.region_error')}</S.RegionError>}
      </S.Wrapper>
    );
  }
}

EditIndicator.propTypes = {
  t: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    getIndicatorRequest: PropTypes.func.isRequired,
    fetchCountryRequest: PropTypes.func.isRequired,
    updateIndicatorDataRequest: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  data: PropTypes.array,
  reportingPeriodType: PropTypes.string,
  fields: PropTypes.array,
  regions: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isUpdateLoading: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
};

EditIndicator.defaultProps = {
  data: [],
  isUpdateLoading: false,
  regions: [],
  fields: [],
  reportingPeriodType: undefined,
};

const mapStateToProps = ({
  indicators: {
    data: {
      data,
      fields,
      regions,
      reportingPeriodType,
      dataSource,
    },
    isLoading,
    isUpdateLoading,
    error,
    successMessage,
  },
}) => ({
  data,
  dataSource,
  fields,
  regions,
  reportingPeriodType,
  isLoading,
  isUpdateLoading,
  error,
  successMessage,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...indicatorsActions,
    ...countryActions,
  }, dispatch),
});

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(EditIndicator)),
);
