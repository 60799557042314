import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Modal from '@material-ui/core/Modal';

import { isCountryAdminOn, isCountryManagerOn } from 'helpers/roles';
import Form from './Form';

import * as S from './styled';

const UserModal = ({
  open,
  initialValues,
  onSubmit,
  isFormDisabled,
  countries,
  allowedCountries,
  onClose,
  userId,
  userRole,
  currentUser,
  t,
}) => {
  const isEditing = !!initialValues.id;
  const title = t('admin.share_access_with_user'); // isEditing ? t('admin.edit_user') : t('admin.share_access_with_user');
  const isEditingCurrentUser = initialValues.id === userId;

  const isAdminOrManagerOfAtLeastOneProject = (user, projects) => {
    const projectsIds = projects.map((p) => p.id);
    const managedProjectsIds = Object.keys(user.permissions.projects).filter((k) => user.permissions.projects[k] == 'admin' || user.permissions.projects[k] == 'manager').map((id) => parseInt(id));

    return projectsIds.filter((id) => managedProjectsIds.includes(id)).length;
  };

  const shouldSeeTheCountry = (country) => isCountryAdminOn(currentUser, country.id) || isCountryManagerOn(currentUser, country.id) || isAdminOrManagerOfAtLeastOneProject(currentUser, country.projects);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <S.Modal>
        <S.Title>{title}</S.Title>
        <Form
          allowedCountriesIds={allowedCountries.map((item) => item.id)}
          onSubmit={onSubmit}
          initialValues={initialValues}
          isEditing={isEditing}
          isEditingCurrentUser={isEditingCurrentUser}
          isFormDisabled={isFormDisabled}
          countries={countries.filter(shouldSeeTheCountry)}
          onClose={onClose}
          userRole={userRole}
          currentUser={currentUser}
        />
      </S.Modal>
    </Modal>
  );
};

UserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  allowedCountries: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UserModal);
