import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';

import DACompassLogo from 'images/da-compass-logo-light.svg';

export const Container = styled(Flex).attrs(() => ({
  alignItems: 'space-beetw',
  flexDirection: 'row',
  justifyContent: 'space-between',
  mx: 'auto',
}))`
  bottom: 0;

  max-width: ${({ theme }) => theme.layout.contentMaxWidth || null};

  padding-top: 4rem;
  padding-top: 2rem;
  position: relative;

  @media print {
    height: 150px;
  }

  @media screen and (max-width: 430px) {
    padding: 0 12px;
  }
`;

export const TextContainer = styled(Flex).attrs(() => ({
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'space-between',
  px: 24,
}))`
  bottom: 0;
  height: 100px;
  position: relative;

  @media screen and (max-width: 430px) {
    padding: 0 12px;
  }
`;

export const Wrapper = styled(Box).attrs(() => ({
  width: 1,
}))`
  background: #181e24;
  width: 100%;
  padding: 0 1rem;
  @media print {
    display: none;
  }
`;

export const TextPrimary = styled(Box).attrs(() => ({
  fontSize: 12,
  pt: 30,
}))`
  color: ${({ theme }) => theme.colors.footerGray};
`;

export const TextSecondary = styled(Box).attrs(() => ({
  fontSize: 12,
  pt: '4px',
}))`
  color: ${({ theme }) => theme.colors.primaryWhite};
`;

export const Logo = styled.img.attrs(() => ({
  src: DACompassLogo,
}))`
  height: 48px;
  padding-top: 32px;
  padding-bottom: 16px;
  width: 248px;
  margin-left: 30px;
`;

export const LinkContainer = styled.span`
  padding-left: 5px;
  margin-left: 5px;

  @media print {
    display: none;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: white;
  transition: color 0.2s ease-in-out;
  padding: 0 1rem;
  :hover {
    color: #ffffff;
  }

  @media print {
    display: none;
  }
`;

export const YellowButton = styled.a`
width: 108px;
height: 44px;
margin: 0px 0 10.5px 29px;
padding: 16px 23px 14px 21px;
border-radius: 22px;
background-color: #fee900;
text-decoration: none;
color: ${({ theme }) => theme.colors.primaryDark};
`;
