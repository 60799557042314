import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  padding: 0 1rem;

  && {
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }

`;

export const Select = styled.select`
  background: #FCFBFB;
  border: 1px solid #DDE3ED;
  padding: 3px;
  border-radius: 4px;
  font-size: 12px;
  
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Div = styled.div``;

export const CountryRow = styled.div`
  color: ${({ theme }) => theme.colors.primaryDark};
`;
