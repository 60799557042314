import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation } from 'react-i18next';

import * as S from './styled';

const Header = ({ t }) => {
  const headRows = [t('admin.column_name'), t('admin.can_filter'), t('admin.reorder')];

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <S.Cell
            key={index}
          >
            <S.Wrapper>
              {row}
            </S.Wrapper>
          </S.Cell>
        ))}
        <S.Cell />
      </TableRow>
    </TableHead>
  );
};

Header.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Header);
