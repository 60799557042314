import React from 'react';
import PropTypes from 'prop-types';
import { VictoryPie } from 'victory';
import { withTranslation } from 'react-i18next';

import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';

import { MILESTONES_STATUSES } from 'configs/statuses';
import * as S from './styled';
import Label from './Label';

const getSectorColor = ({ datum }) => (datum.x === 'other'
  ? '#eeeeee'
  : MILESTONES_STATUSES[datum.x].color
);

// Resolves charts dependancy
charts(FusionCharts);

const FusionPieChart = ({
  data,
  period,
  isMilestonePage,
  t,
}) => {
  const sortedData = [...data].reverse();

  const dataSource = {
    chart: {
      showpercentvalues: '1',
      legendposition: 'bottom',
      showLabels: false,
      showValues: false,
      theme: 'fusion',
      doughnutRadius: 85,
      showLegend: false,
      bgAlpha: '0',
      startingAngle: '90',
      paletteColors: `${sortedData.map((datum) => `${getSectorColor({ datum })}`)}`,
    },
    data: sortedData.map((d) => ({
      label: t(`common.${MILESTONES_STATUSES[d.x].description}`),
      value: d.y,
    })),
  };

  return data.length ? (
    <S.StyledPaper>
      <S.Header>
        <S.Title>{isMilestonePage ? t('common.milestones_status') : t('common.status')}: {period}</S.Title>
      </S.Header>
      <S.Body>
        <ReactFusioncharts
          type="doughnut2d"
          width="300"
          height="320"
          dataFormat="JSON"
          containerBackgroundOpacity="0"
          dataSource={dataSource}
        />

        <svg viewBox="360 25 365 400" style={{ width: '230px' }}>
          {sortedData.map((datum) => <Label datum={datum} small={data.length > 4} />)}
        </svg>

        <S.TotalMilestone small={data.length > 4}>
          <S.TotalMilestoneLabel small={data.length > 4}>{t('common.milestones')}</S.TotalMilestoneLabel>
          <S.TotalMilestoneNumber small={data.length > 4}>
            {sortedData.reduce((acc, cur) => acc + cur.y, 0)}
          </S.TotalMilestoneNumber>
        </S.TotalMilestone>
      </S.Body>
    </S.StyledPaper>
  ) : <S.PieContainer />;
};

FusionPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  period: PropTypes.string.isRequired,
  isMilestonePage: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FusionPieChart);
