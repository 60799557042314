import { handleActions, createActions } from 'redux-actions';
import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_HISTORY_API_REQUEST: undefined,
  GET_HISTORY_API_SUCCESS: undefined,
  GET_HISTORY_API_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getHistoryApiRequest, handlers.getHistoryApiRequest],
    [actions.getHistoryApiSuccess, handlers.getHistoryApiSuccess],
    [actions.getHistoryApiFailure, handlers.getHistoryApiFailure],
  ]),
  initialState,
);

export default reducer;
