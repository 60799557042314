import styled from 'styled-components';
import { Paper, Table } from '@material-ui/core';

export const Root = styled.div`
  box-sizing: border-box;
  max-width: ${({ theme }) => theme.layout.contentMaxWidth || null};
  padding: ${({ theme }) => theme.layout.contentPadding};
  margin: auto ${({ theme }) => (theme.isInternetExplorer ? '0' : '20px')};
  width: 100%;
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const StyledPaper = styled(Paper)`
  && {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const StyledTable = styled(Table).attrs((props) => ({
  'aria-labelledby': props['aria-labelledby'] || '',
}))`
  && {
    min-width: 750px;

    .MuiTableCell-head {
      background: #eef1f5;
      border: 1px solid #d9dee4;
      padding: 0.5rem;
    }
  }
`;

export const FilterToolbar = styled.div`
&& {
  button {
    background: white;
    border: 1px solid #d9dee4;
    border-bottom: 0;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }

  button:first-child {
    border-radius: 4px 0 0 0;
  }

  button:last-child {
    border-radius: 0 4px 0 0;
  }

  button.selected {
    background: rgb(217 222 231);
  }
}
`;
