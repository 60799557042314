import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import Header from './Header';
import Body from './Body';

import * as S from './styled';

const EditColumnModal = ({
  open,
  onSubmit,
  onClose,
  onAddColumn,
  customColumns,
  onOffsetPosition,
  onFilterableColumn,
  onColumnName,
  onDelete,
  t,
}) => (
  <Modal
    open={open}
    onClose={onClose}
  >
    <S.Modal>
      <S.Header>
        <S.Title>
          {t('admin.edit_column')}
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </S.Title>
        <Tooltip title={t('admin.add_column')}>
          <S.AddButton aria-label="Add Column" onClick={onAddColumn}>
            <S.AddIcon />
            {t('admin.add_column')}
          </S.AddButton>
        </Tooltip>
      </S.Header>
      <S.StyledTable aria-labelledby="tableTitle">
        <Header />
        <Body
          customColumns={customColumns}
          onOffsetPosition={onOffsetPosition}
          onFilterableColumn={onFilterableColumn}
          onColumnName={onColumnName}
          onDelete={onDelete}
        />
      </S.StyledTable>
      <S.CloseButton onClick={onClose}>
        {t('common.close')}
      </S.CloseButton>
      <S.SaveButton onClick={onSubmit}>
        {t('common.save')}
      </S.SaveButton>
    </S.Modal>
  </Modal>
);

EditColumnModal.propTypes = {
  customColumns: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOffsetPosition: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFilterableColumn: PropTypes.func.isRequired,
  onAddColumn: PropTypes.func.isRequired,
  onColumnName: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EditColumnModal);
