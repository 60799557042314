import getErrorMessage from 'helpers/getErrorMessage';

const initialState = {
  historyApi: {
    results: [],
    total: 0,
  },
  isLoading: true,
  error: '',
};

export const getHistoryApiRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getHistoryApiSuccess = (state, { payload }) => ({
  ...state,
  historyApi: payload,
  isLoading: false,
});

export const getHistoryApiFailure = (state, { payload }) => ({
  ...state,
  error: getErrorMessage(payload),
  isLoading: false,
});

export default initialState;
