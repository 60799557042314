import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import * as S from './styled';

const Switch = ({ checked, disabled, onClick }) => (
  <S.Wrapper>
    <S.MaterialSwitch checked={checked} onClick={onClick} name="checked" disabled={disabled} />
  </S.Wrapper>
);

Switch.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

Switch.defaultProps = {
  checked: true,
};

export default withTranslation()(Switch);
