import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

export const Cell = styled(TableCell).attrs(() => ({

}))`
  &.MuiTableCell-root {
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #7f7f7f;
    padding: 0;
    padding-bottom: 14px;
  }

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    padding-right: 25px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
