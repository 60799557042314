import getErrorMessage from 'helpers/getErrorMessage';
import getIsSystemIndicatorsGroups from 'helpers/getIsSystemIndicatorsGroups';
import i18n from 'locales';

const initialState = {
  indicatorGroups: [],
  responsibleParties: [],
  isLoading: false,
  isModalMilestoneOpen: false,
  isModalColumnOpen: false,
  isMilestonesLoading: false,
  isColumnsLoading: false,
  selectedMilestoneId: null,
  milestones: null,
  customColumns: [],
  selectedColumn: {},
  milestone: {},
  filters: {},
  error: '',
  successMessage: '',
  graphData: [],
  isGraphLoading: true,
  upcoming: [],
  isUpcomingLoading: true,
};

export const getIndicatorGroupsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getIndicatorGroupsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  indicatorGroups: getIsSystemIndicatorsGroups(payload),
});

export const getIndicatorGroupsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const getResponsiblePartiesRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const getResponsiblePartiesSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  responsibleParties: payload,
});

export const getResponsiblePartiesFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const getMilestonesRequest = (state) => ({
  ...state,
  isMilestonesLoading: true,
  milestones: null,
});

export const getMilestonesSuccess = (state, { payload }) => ({
  ...state,
  isMilestonesLoading: false,
  milestones: payload,
});

export const getMilestonesFailure = (state, { payload }) => ({
  ...state,
  isMilestonesLoading: false,
  error: getErrorMessage(payload),
});

export const getMilestonesGraphRequest = (state) => ({
  ...state,
  graphData: [],
  isGraphLoading: true,
});

export const getMilestonesGraphSuccess = (state, { payload }) => ({
  ...state,
  graphData: payload,
  isGraphLoading: false,
});

export const getMilestonesGraphFailure = (state, { payload }) => ({
  ...state,
  isGraphLoading: false,
  error: getErrorMessage(payload),
});

export const getUpcomingMilestonesRequest = (state) => ({
  ...state,
  upcoming: [],
  isUpcommingLoading: true,
});

export const getUpcomingMilestonesSuccess = (state, { payload }) => ({
  ...state,
  upcoming: payload,
  isUpcommingLoading: false,
});

export const getUpcomingMilestonesFailure = (state, { payload }) => ({
  ...state,
  isUpcommingLoading: false,
  error: getErrorMessage(payload),
});

export const createMilestoneRequest = (state) => ({
  ...state,
  isLoading: true,
  successMessage: '',
});

export const createMilestoneSuccess = (state) => ({
  ...state,
  isLoading: false,
  milestone: {},
  isModalMilestoneOpen: false,
  successMessage: i18n.t('admin.milestone_successfully_created'),
});

export const createMilestoneFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const deleteMilestoneRequest = (state) => ({
  ...state,
  isLoading: true,
  successMessage: '',
});

export const deleteMilestoneSuccess = (state) => ({
  ...state,
  isLoading: false,
  successMessage: i18n.t('admin.milestone_successfully_deleted'),
});

export const deleteMilestoneFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const updateMilestoneRequest = (state) => ({
  ...state,
  isLoading: true,
  successMessage: '',
});

export const updateMilestoneSuccess = (state) => ({
  ...state,
  milestone: {},
  isLoading: false,
  isModalMilestoneOpen: false,
  successMessage: i18n.t('admin.milestone_successfully_updated'),
});

export const updateMilestoneFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const openAddEditModalMilestone = (state, { payload }) => ({
  ...state,
  milestone: payload,
  isModalMilestoneOpen: true,
});

export const openAddEditModalColumn = (state, { payload }) => ({
  ...state,
  selectedColumn: payload,
  isModalColumnOpen: true,
});

export const closeAddEditModal = (state) => ({
  ...state,
  milestone: {},
  selectedColumn: {},
  isModalMilestoneOpen: false,
  isModalColumnOpen: false,
});

export const getColumnsRequest = (state) => ({
  ...state,
  isColumnsLoading: true,
  customColumns: [],
});

export const getColumnsSuccess = (state, { payload }) => ({
  ...state,
  isColumnsLoading: false,
  customColumns: payload,
});

export const getColumnsFailure = (state, { payload }) => ({
  ...state,
  isColumnsLoading: false,
  error: getErrorMessage(payload),
});

export const createColumnRequest = (state) => ({
  ...state,
  isLoading: true,
  successMessage: '',
});

export const createColumnSuccess = (state) => ({
  ...state,
  isLoading: false,
  column: {},
  isModalColumnOpen: false,
  successMessage: i18n.t('admin.column_successfully_created'),
});

export const createColumnFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const deleteColumnRequest = (state) => ({
  ...state,
  isLoading: true,
  successMessage: '',
});

export const deleteColumnSuccess = (state) => ({
  ...state,
  isLoading: false,
  isModalColumnOpen: false,
  successMessage: i18n.t('admin.column_successfully_deleted'),
});

export const deleteColumnFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const updateColumnRequest = (state) => ({
  ...state,
  isLoading: true,
  successMessage: '',
});

export const updateColumnSuccess = (state) => ({
  ...state,
  column: {},
  customColumns: [],
  isLoading: false,
  isModalColumnOpen: false,
  successMessage: i18n.t('admin.column_successfully_updated'),
});

export const updateColumnFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const resetToInitialState = () => ({
  ...initialState,
});

export default initialState;
