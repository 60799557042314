import { STATUSES } from './statuses';
import { chartColorPalette } from '../theme.js';

const getChartColor = (datum) => {
  const defaultColor = '#9DDAE0';

  if (STATUSES[datum.status]?.color) {
    return STATUSES[datum.status]?.color;
  } if (datum.colorPalette) {
    const colorKey = datum.colorPalette?.split('_')[0];
    return chartColorPalette[colorKey][0];
  }

  return defaultColor;
};

export default getChartColor;
