export const FOOTER_LINKS = [
  {
    textKey: 'footer.tutorials',
    href: 'https://www.da.digital/tutorial-category/1-creating-projects-and-cards',
    target: '_blank',
  },
  {
    textKey: 'footer.dadigital',
    href: 'https://www.da.digital/',
    target: '_blank',
  },
  {
    textKey: 'footer.privacy_policy',
    href: 'https://www.deliveryassociates.com/legal/privacy-policy',
    target: '_blank',
  },
  {
    textKey: 'footer.terms_and_conditions',
    href: 'https://www.deliveryassociates.com/legal/terms-and-conditions',
    target: '_blank',
  },
];

export default FOOTER_LINKS;
