import moment from 'moment';
import i18n from 'locales';

export const validate = ({
  indicatorId,
  name,
  status,
  remarks,
  completionDate,
  startDate,
  // meetingDate,
  participants,
}) => {
  const errors = {};

  if (!indicatorId) {
    errors.indicatorId = i18n.t('invalid.indicator_is_required');
  }

  if (!name) {
    errors.name = i18n.t('invalid.milestone_is_required');
  }

  if (name && name.length > 200) {
    errors.name = i18n.t('invalid.milestones_is_too_long');
  }

  if (!status) {
    errors.status = i18n.t('invalid.status_is_required');
  }

  // if (!meetingDate) {
  //   errors.meetingDate = i18n.t('invalid.meeting_date_is_required');
  // }

  if (remarks && remarks.length > 600) {
    errors.remarks = i18n.t('invalid.remark_is_too_long');
  }

  // if (!participants) {
  //   errors.participants = i18n.t('invalid.participants_is_required');
  // }

  if (participants && participants.length > 250) {
    errors.participants = i18n.t('invalid.participants_is_too_long');
  }

  if (completionDate && startDate && moment(startDate).isAfter(completionDate)) {
    errors.startDate = i18n.t('invalid.start_date');
    errors.completionDate = i18n.t('invalid.completion_date');
  }

  return errors;
};

export const validateCustomColumnsData = (customColumnsData) => {
  const errorsArray = [];

  customColumnsData.forEach((column) => {
    if (column?.value && column.value.length > 200) {
      errorsArray.push({ value: i18n.t('invalid.value_is_too_long') });
    }

    errorsArray.push({});
  });

  return errorsArray;
};

export const blank = () => {};
