import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AddButton } from '../styled';

export const Cell = styled(TableCell).attrs(() => ({

}))`
  &.MuiTableCell-root {
    padding: 14px 0;
  }

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    padding-right: 25px;
  }
`;
export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 319px;

    .MuiInput-underline::before {
      border-bottom: solid 1px ${({ theme }) => theme.colors.tableGray};
    }
  }
`;

export const Order = styled.div`
  display: flex;
`;

export const DropUp = styled(AddButton)`
  && {
    width: 30px;
    min-width: auto;
    opacity: ${({ disabled }) => disabled && 0.2};
    margin-right: 10px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DropDown = styled(DropUp)`
`;

export const ArrowDropUp = styled(ArrowDropUpIcon)`
  && {
    color: ${({ theme }) => theme.colors.appBackground};
  }
`;

export const ArrowDropDown = styled(ArrowDropDownIcon)`
  && {
    color: ${({ theme }) => theme.colors.appBackground};
  }
`;
