import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const Container = styled(Flex).attrs(() => ({
  justifyContent: 'center',
}))`

`;

export const Blank = {};
