import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { withSnackbar } from 'notistack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withTranslation } from 'react-i18next';

import { actions as milestonesActions } from 'redux/milestones';
import { actions as appActions } from 'redux/app';
import { actions as countriesActions } from 'redux/countries';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import AmplitudeService from 'services/amplitude';
import Loader from 'components/Loader';
import ConfirmationModal from 'components/ConfirmationModal';
import ToggleButtonGroup from 'components/material/ToggleButtonGroup';
import Timeline from 'components/Timeline';

import Filters from './Filters';
import MilestoneModal from './Modals/Milestone';
import EditColumnModal from './Modals/EditColumn';
import Toolbar from './Toolbar';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import * as S from './styled';
import { options, toggleViewItems } from './config';

class Milestones extends Component {
  state = {
    pagination: {
      page: 0,
      rowsPerPage: 10,
      order: 'asc',
      sort: 'id',
    },
    filters: {},
    view: toggleViewItems[0].value,
    isCustomColumn: false,
    customColumns: [],
  }

  static propTypes = {
    actions: PropTypes.shape({
      resetToInitialState: PropTypes.func.isRequired,
      getIndicatorGroupsRequest: PropTypes.func.isRequired,
      getResponsiblePartiesRequest: PropTypes.func.isRequired,
      getMilestonesRequest: PropTypes.func.isRequired,
      openAddEditModalMilestone: PropTypes.func.isRequired,
      openAddEditModalColumn: PropTypes.func.isRequired,
      closeAddEditModal: PropTypes.func.isRequired,
      createMilestoneRequest: PropTypes.func.isRequired,
      updateMilestoneRequest: PropTypes.func.isRequired,
      setConfirmModalState: PropTypes.func.isRequired,
      deleteMilestoneRequest: PropTypes.func.isRequired,
      deleteColumnRequest: PropTypes.func.isRequired,
      getColumnsRequest: PropTypes.func.isRequired,
      updateColumnRequest: PropTypes.func.isRequired,
      createColumnRequest: PropTypes.func.isRequired,
    }).isRequired,
    indicatorGroups: PropTypes.array.isRequired,
    responsibleParties: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    isMilestonesLoading: PropTypes.bool.isRequired,
    isModalMilestoneOpen: PropTypes.bool.isRequired,
    isModalColumnOpen: PropTypes.bool.isRequired,
    milestones: PropTypes.object.isRequired,
    customColumns: PropTypes.array.isRequired,
    milestone: PropTypes.object.isRequired,
    selectedColumn: PropTypes.object.isRequired,
    successMessage: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    isConfirmModalOpen: PropTypes.bool.isRequired,
    idForDeletion: PropTypes.number.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        sectionId: PropTypes.string.isRequired,
        projectId: PropTypes.string.isRequired,
        countryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const { actions, match } = this.props;
    const { sectionId, countryId, projectId } = match.params;

    actions.getIndicatorGroupsRequest({ sectionId, countryId, projectId });
    actions.getResponsiblePartiesRequest();
    AmplitudeService.logEvent('Page was visited', { pageTitle: 'Milestones' });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      successMessage,
      error,
      enqueueSnackbar,
      actions,
      match,
      customColumns,
    } = this.props;
    const { filters: { indicatorId } } = this.state;
    const { sectionId, countryId, projectId } = match.params;

    if (successMessage && (prevProps.successMessage !== successMessage)) {
      enqueueSnackbar(successMessage, { variant: 'success' });
      this.handleChangePage(null, 0);
      actions.getIndicatorGroupsRequest({
        sectionId, countryId, projectId, indicatorId,
      });
      actions.getResponsiblePartiesRequest();
    }

    if (error && (prevProps.error !== error)) {
      enqueueSnackbar(error, { variant: 'error' });
    }

    if (prevState.filters?.indicatorId !== indicatorId) {
      actions.getColumnsRequest({ indicatorId });
    }

    if (!isEqual(prevProps.customColumns, customColumns)) {
      /* eslint-disable */
      this.setState({ customColumns });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.resetToInitialState();
  }

  onToggleChange = (value) => this.setState({ view: value });

  handleFiltersChange = (filters) => {
    const { actions, match } = this.props;

    const { sectionId, projectId, countryId } = match.params;
    const { indicatorId, indicatorsGroupId } = filters;

    this.setState({ filters });
    actions.getMilestonesRequest({
      sectionId,
      projectId,
      countryId,
      indicatorId,
      indicatorsGroupId,
      ...filters,
      ...this.state.pagination,
    });
  }

  handleChangeRowsPerPage = (event) => {
    const { actions, match } = this.props;
    const { sectionId, countryId, projectId } = match.params;

    this.setState({
      pagination: {
        page: 0,
        rowsPerPage: +event.target.value,
      },
    });

    actions.getMilestonesRequest({
      ...this.state.filters, page: 0, rowsPerPage: +event.target.value, sectionId, projectId, countryId,
    });
  };

  handleChangePage = (event, newPage) => {
    const { actions, match } = this.props;
    const { sectionId, projectId, countryId } = match.params;

    this.setState((state) => ({
      pagination: {
        page: newPage,
        rowsPerPage: state.pagination.rowsPerPage,
      },
    }));

    actions.getMilestonesRequest({
      ...this.state.filters, ...this.state.pagination, page: newPage, sectionId, projectId, countryId,
    });
  };

  openModalMilestone = (params = {}) => {
    const { actions } = this.props;

    actions.openAddEditModalMilestone(params);
  }

  openModalColumn = (params = {}) => {
    const { actions } = this.props;

    actions.openAddEditModalColumn(params);
  }

  openModalEditColumn = (params = {}) => {
    const { actions } = this.props;

    actions.openAddEditModalColumn(params);
  }

  closeModal = () => {
    const { actions, customColumns } = this.props;

    actions.closeAddEditModal();
    this.setState({ customColumns });
  }

  onAddEditSubmit = (values) => {
    const { actions, match, indicatorGroups } = this.props;

    const customColumnsData = values.customColumnsData.map((column) => ({
      id: column.id || undefined,
      value: column.value || '',
      customColumnId: column.customColumnId,
    }));

    const milestoneData = {
      indicatorId: values.indicatorId,
      name: values.name,
      completionDate: values.completionDate,
      startDate: values.startDate,
      responsibleParty: values.responsibleParty,
      status: values.status,
      remarks: values.remarks,
      meetingDate: values.meetingDate,
      participants: values.participants,
      customColumnsData,
    };

    const { sectionId, countryId, projectId } = match.params;

    const indicatorsArray = [];
    let getindicatorsGroupId = '';

    indicatorGroups.map((indicatorGroup) => indicatorsArray.push(...indicatorGroup.indicators));
    getindicatorsGroupId = indicatorsArray.find((el) => el.id === values.indicatorId).indicatorsGroupId;

    if (values.id) {
      const milestoneId = values.id;

      actions.updateMilestoneRequest({
        sectionId, projectId, countryId, milestoneData, indicatorGroupId: getindicatorsGroupId, milestoneId,
      });
    } else {
      actions.createMilestoneRequest({
        sectionId, indicatorGroupId: getindicatorsGroupId, milestoneData, countryId, projectId,
      });
    }

    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        customColumnsDataFilter: {},
      },
    }));
  }

  handleConfirmClose = () => {
    const { actions } = this.props;

    actions.setConfirmModalState({ state: false, id: 0 });
  };

  handleConfirmClickOpen = (id, isCustomColumn = false) => {
    const { actions } = this.props;

    this.setState({ isCustomColumn });
    actions.setConfirmModalState({ state: true, id });
  };

  onConfirmSubmit = () => {
    const {
      actions, idForDeletion, match,
    } = this.props;
    const {
      filters: { indicatorId, indicatorsGroupId },
    } = this.state;

    const { sectionId, projectId, countryId } = match.params;

    actions.deleteMilestoneRequest({
      milestoneId: idForDeletion,
      indicatorId,
      countryId,
      projectId,
      sectionId,
      indicatorGroupId: indicatorsGroupId,
    });
    actions.setConfirmModalState({ state: false, id: 0 });
    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        customColumnsDataFilter: {},
      },
    }));
  };

  onAddEditСolumnSubmit = () => {
    const { actions } = this.props;
    const { customColumns, filters: { indicatorId } } = this.state;

    const newCustomColumns = customColumns.map(({ id, isNewColumn, ...column }, index) => (isNewColumn ? { ...column, order: index + 1 } : { ...column, id, order: index + 1 }));

    actions.updateColumnRequest({
      indicatorId,
      customColumns: newCustomColumns,
    });
  };

  onDeleteColumn = () => {
    const { actions, idForDeletion } = this.props;
    const { filters } = this.state;

    actions.deleteColumnRequest({ indicatorId: filters.indicatorId, customColumnId: idForDeletion });
    actions.setConfirmModalState({ state: false, id: 0 });

    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        customColumnsDataFilter: {},
      },
    }));
  };

  handleOffsetPosition = (id, index) => {
    const { customColumns } = this.state;
    const cloneCustomColumns = cloneDeep(customColumns);

    const selectedColumn = cloneCustomColumns.find((item) => item.id === id);

    const filteredCustomColumns = cloneCustomColumns.filter((item) => item.id !== id);
    filteredCustomColumns.splice(index, 0, selectedColumn);

    this.setState({ customColumns: filteredCustomColumns });
  };

  handleDeleteCustomColumn = (id) => {
    const { customColumns } = this.state;
    const cloneCustomColumns = cloneDeep(customColumns);

    this.setState({ customColumns: cloneCustomColumns.filter((item) => item.id !== id) });
  };

  handleCreateColumn = () => {
    const { customColumns, filters: { indicatorId } } = this.state;
    const newColumn = {
      id: Date.now(),
      isNewColumn: true,
      indicatorId,
      isFilterable: false,
      isSystem: false,
      milestoneField: null,
      name: '',
      order: customColumns.length + 1,
    };

    const cloneCustomColumns = cloneDeep(customColumns);
    const newCustomColumns = [...cloneCustomColumns, newColumn];

    this.setState({ customColumns: newCustomColumns });
  };

  handleFilterableColumn = (id) => {
    const { customColumns } = this.state;

    const cloneCustomColumns = customColumns.map(
      (column) => (column.id === id ? { ...column, isFilterable: !column.isFilterable } : column),
    );

    this.setState({ customColumns: cloneCustomColumns });
  };

  handleColumnName = (value, id) => {
    const { customColumns } = this.state;

    const cloneCustomColumns = customColumns.map((column) => (column.id === id ? { ...column, name: value } : column));

    this.setState({ customColumns: cloneCustomColumns });
  };

  getModalsProps = () => {
    const {
      isModalMilestoneOpen,
      milestone,
      indicatorGroups,
      responsibleParties,
      isConfirmModalOpen,
      isModalColumnOpen,
      isLoading,
      customColumns,
    } = this.props;
    const { filters } = this.state;

    const indicators = [];
    indicatorGroups.forEach((indicatorGroup) => {
      indicators.push(...indicatorGroup.indicators.map((ind) => ({ value: ind.id, name: ind.title })));
    });

    const indicatorTitle = this.state.filters.indicatorId
      ? indicators.filter((indicator) => indicator.value === this.state.filters.indicatorId)[0].name
      : '';

    const milestoneModalProps = {
      open: isModalMilestoneOpen,
      initialValues: milestone,
      customColumns,
      onClose: this.closeModal,
      onSubmit: this.onAddEditSubmit,
      indicatorsOptions: indicators,
      isLoading,
      responsibleParties: responsibleParties.map((party) => party.name),
      isFormDisabled: false,
      filters,
    };

    const editColumnModalProps = {
      open: isModalColumnOpen,
      customColumns: this.state.customColumns,
      onClose: this.closeModal,
      onDelete: this.handleDeleteCustomColumn,
      onOffsetPosition: this.handleOffsetPosition,
      onFilterableColumn: this.handleFilterableColumn,
      onColumnName: this.handleColumnName,
      onAddColumn: this.handleCreateColumn,
      onSubmit: this.onAddEditСolumnSubmit,
    };

    const confirmModalProps = {
      isOpen: isConfirmModalOpen,
      onSubmit: this.state.isCustomColumn ? this.onDeleteColumn : this.onConfirmSubmit,
      onClose: this.handleConfirmClose,
    };

    return {
      milestoneModalProps,
      editColumnModalProps,
      confirmModalProps,
      indicatorTitle,
    };
  }

  render() {
    const {
      indicatorGroups,
      responsibleParties,
      milestones,
      isMilestonesLoading,
      match,
      t,
      customColumns,
      history: { goBack },
    } = this.props;
    const { projectId, countryId } = match.params;

    // the customColumns table does not support localization; this lookup is a quick-fix / workaround for the issue.
    const lookups = {
      'Date de réunion': 'common.meeting_date',
      Participants: 'common.participants',
      'Date de début': 'common.start_date',
      Activité: 'common.milestones',
      Responsable: 'common.responsible_party',
      "Date d'achèvement estimée": 'common.estimated_completion_date',
      Observation: 'common.remarks',
    };

    const localizedCustomColumns = customColumns.map((c) => (
      { ...c, name: t(lookups[c.name] ? lookups[c.name] : c.name) }
    ));

    const {
      pagination: {
        rowsPerPage,
        page,
      },
    } = this.state;
    const {
      milestoneModalProps,
      confirmModalProps,
      editColumnModalProps,
      indicatorTitle,
    } = this.getModalsProps();

    return (
      <S.Container>
        <S.StyledButton
          onClick={() => goBack()}
        >
          <ArrowBackIcon /> {t('manageDashboard.back_to_sections')}
        </S.StyledButton>
        <S.Title>
          {t('common.milestones')}
        </S.Title>
        {indicatorGroups.length ? (
          <Filters
            indicatorGroups={indicatorGroups}
            responsibleParties={responsibleParties}
            onChange={this.handleFiltersChange}
            customColumns={localizedCustomColumns}
          />
        ) : <Loader />}
        {milestones && (
          <ToggleButtonGroup
            value={this.state.view}
            onChange={this.onToggleChange}
            items={toggleViewItems}
          />
        )}
        <S.Content>
          {this.state.view === 'details' && (
            <>
              {milestones && (
                <>
                  <S.ToolbarWrapper>
                    <Toolbar onAddMilestone={this.openModalMilestone} onEditColumn={this.openModalEditColumn} />
                  </S.ToolbarWrapper>
                  <S.StyledPaper>
                    <S.TableWrapper>
                      <S.StyledTable aria-labelledby="tableTitle">
                        <Header
                          headRows={localizedCustomColumns}
                        />
                        <Body
                          numberRows={localizedCustomColumns.length}
                          onDelete={this.handleConfirmClickOpen}
                          onEditMilestone={this.openModalMilestone}
                          rows={milestones ? milestones.results : []}
                          isLoading={isMilestonesLoading}
                        />
                      </S.StyledTable>
                    </S.TableWrapper>
                  </S.StyledPaper>
                </>
              )}
              {(milestones && !isMilestonesLoading) && (
                <Pagination
                  rowsPerPageOptions={options.rowsPerPageOptions}
                  count={milestones.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              )}
            </>
          )}
          {this.state.view === 'timeline' && (
            <Timeline milestones={milestones ? milestones.results : []} indicatorTitle={indicatorTitle} />
          )}
        </S.Content>
        <MilestoneModal {...milestoneModalProps} />
        <EditColumnModal {...editColumnModalProps} />
        <ConfirmationModal {...confirmModalProps} />
      </S.Container>
    );
  }
}

const mapStateToProps = ({
  milestones: {
    isLoading,
    isModalMilestoneOpen,
    isModalColumnOpen,
    indicatorGroups,
    responsibleParties,
    milestones,
    customColumns,
    milestone,
    selectedColumn,
    successMessage,
    error,
    isMilestonesLoading,
  },
  app: {
    isConfirmModalOpen,
    idForDeletion,
  },
}) => ({
  isLoading,
  isMilestonesLoading,
  isModalMilestoneOpen,
  isModalColumnOpen,
  indicatorGroups,
  responsibleParties,
  milestones,
  customColumns,
  milestone,
  selectedColumn,
  error,
  successMessage,
  isConfirmModalOpen,
  idForDeletion,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...milestonesActions,
    ...countriesActions,
    ...appActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withTranslation()(Milestones)));
