import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled.js';

const TableSortLabel = ({ children, ...restProps }) => (
  <S.StyledTableSortLabel
    {...restProps}
  >{children}
  </S.StyledTableSortLabel>
);

TableSortLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default TableSortLabel;
