export const options = {
  rowsPerPageOptions: [5, 10, 15, 25],
};

export const columns = [
  {
    label: 'meeting_date',
    key: 'meetingDate',
    align: 'left',
  },
  {
    label: 'participants',
    key: 'participants',
    align: 'left',
  },
  {
    label: 'milestones',
    key: 'name',
    align: 'left',
  },
  {
    label: 'responsible_party',
    key: 'responsibleParty',
    align: 'left',
  },
  {
    label: 'start_date',
    key: 'startDate',
    align: 'left',
  },
  {
    label: 'estimated_completion_date',
    key: 'completionDate',
    align: 'left',
  },
  {
    label: 'remarks',
    key: 'remarks',
    align: 'left',
  },
  {
    label: 'status',
    key: 'status',
    align: 'left',
  },
  {
    label: 'actions',
    key: 'actions',
    align: 'center',
  },
];

export const toggleViewItems = [
  {
    value: 'details',
    label: 'Details',
  },
  {
    value: 'timeline',
    label: 'Timeline',
  },
];
