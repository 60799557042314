import React from 'react';
import moment from 'moment';

import TableSelect from './TableSelect';
import DateEditor from './DateEditor';

const DATE_FORMAT = 'YYYY-MM-DD';

const dateCellFormatter = ({ value }) => moment(value).format(DATE_FORMAT);

const TABLE_MAX_WIDTH = 1360;
const COLUMN_WIDTH = 160;

const defaultColumnProperties = {
  resizable: true,
};

const getFieldConfigs = (t, { regions }) => ({
  dataId: {
    width: 120,
  },
  date: {
    editor: DateEditor,
    formatter: dateCellFormatter,
    width: 90,
  },
  regionId: {
    width: 140,
    editor: <TableSelect items={regions || []} name="region" />,
  },
  year: {
    width: 70,
  },
  month: {
    width: 70,
  },
  lat: {
    width: 65,
  },
  lng: {
    width: 65,
  },
  delete: {
    name: t('common.delete'),
    key: 'delete',
    className: 'delete',
    width: 85,
  },
});

export const formatTableFields = (fields, regions, t) => {
  const fieldConfigs = getFieldConfigs(t, { regions });

  const {
    matchedFieldsWidthSum,
    customFieldsCount,
    restFieldsCount,
  } = fields.reduce((acc, field) => {
    const fieldConfig = fieldConfigs[field.key];
    if (fieldConfig?.width) {
      acc.matchedFieldsWithWidthCount += 1;
      acc.matchedFieldsWidthSum += fieldConfig.width;
    } else if (field.isCustom) {
      acc.customFieldsCount += 1;
    } else {
      acc.restFieldsCount += 1;
    }

    return acc;
  }, {
    matchedFieldsWidthSum: 0,
    matchedFieldsWithWidthCount: 0,
    customFieldsCount: 0,
    restFieldsCount: 0,
  });

  const width = ((TABLE_MAX_WIDTH - matchedFieldsWidthSum) / (customFieldsCount + restFieldsCount)) < COLUMN_WIDTH
    ? COLUMN_WIDTH : undefined;

  const formatedFields = fields.map((field) => {
    const matchedFiledOptions = fieldConfigs[field.key];
    const formattedField = {
      ...field,
      ...defaultColumnProperties,
      width,
      ...(matchedFiledOptions || {}),
    };

    return formattedField;
  });

  return formatedFields;
};

export const blank = () => {};
