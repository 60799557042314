import styled from 'styled-components';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 39px;
  width: 100%;
  max-width: ${({ theme }) => theme.layout.contentMaxWidth || null};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.primaryDark};
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.19px;
  line-height: 59px;
  margin-bottom: 26px;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 2px solid #cfcfcf;
  margin-bottom: 36px;
`;

export const Loader = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const CircularProgress = styled(MuiCircularProgress)`
  && {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
