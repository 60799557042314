const maxLength = 30;

const addWordJoinerToDash = (string) => {
  const wordJoiner = '\u2060';
  const dash = '-';
  return string.replace(`${dash}`, `${wordJoiner}${dash}${wordJoiner}`);
};

export const formatTitleForCard = (title) => (title.length > maxLength ? `${
  addWordJoinerToDash(title.substr(0, maxLength).trim())
}...` : addWordJoinerToDash(title));

export const noop = () => {};
