import http, { baseUrl } from 'http/index';

export const getHistoryAPI = (params) => http
  .get('aya/history', {
    params: {
      ...params,
    },
  })
  .then((res) => res.data);

export const downloadJson = (id) => `${baseUrl}/api/aya/history/${id}/json`;
