import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

import * as S from './styled';

const EnhancedTableToolbar = ({
  onAddMilestone,
  onEditColumn,
  t,
}) => (
  <Toolbar>
    <S.Wrapper>
      <Tooltip title={t('admin.add_milestone')}>
        <S.AddButton aria-label="Add milestone" onClick={onAddMilestone} padding="9px 12px 7px 9px">
          <S.AddIcon />
          {t('admin.add_milestone')}
        </S.AddButton>
      </Tooltip>
    </S.Wrapper>
    <Tooltip title={t('admin.edit_column')}>
      <S.AddButton aria-label="Edit columns" onClick={onEditColumn} padding="9px 7px 7px 9px">
        <S.EditIcon />
        {t('admin.edit_column')}
      </S.AddButton>
    </Tooltip>
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  onAddMilestone: PropTypes.func.isRequired,
  onEditColumn: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EnhancedTableToolbar);
