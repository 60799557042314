import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const Container = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}))`

`;

export const BetaBox = styled.div`
background: #eef1f5;
color: #26323e;
padding: 2rem;
border-radius: 5px;
max-width: 550px;
margin: 0 auto;
width: 100%;
font-size: 16px;
font-weight: lighter;
line-height: 22px;

h4 {
  margin: 0;
  font-size: 22px;
  font-weight: normal;
}

a {
  color: inherit;
}
`;

export const blank = {};
