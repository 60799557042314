import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export const BackButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  margin: 20px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;

  &.MuiButton-label {
    background: red;
    display: flex;
    justify-content: space-around;
  }
`;

export const Content = styled(Flex).attrs(() => ({
  flex: '1 0 auto',
  flexDirection: 'column',
}))`
  min-height: 350px;
  max-width: ${({ theme }) => theme.layout.contentMaxWidth || null};
  padding: ${({ theme }) => theme.layout.contentPadding};
  width: 100%;
`;
