import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { actions as milestonesActions } from 'redux/milestones';
import { actions as actionsIndicatorDetails } from 'redux/indicatorDetails';
import ToggleButtonGroup from 'components/material/ToggleButtonGroup';
import Timeline from 'components/Timeline';

import scrollToElement from 'helpers/scrollToElement';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import PieChart from './PieChart';
import FusionPieChart from './FusionPieChart';
import Filters from './Filters';
import UpcomingMilestones from './UpcomingMilestones';
import * as S from './styled';

import { toggleViewItems } from './config';

class Milestones extends Component {
  state = {
    view: toggleViewItems[0].value,
    order: 'asc',
    orderBy: 'name',
    rowsPerPage: 10,
    page: 0,
  }

  static propTypes = {
    milestones: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    indicatorsGroupId: PropTypes.number.isRequired,
    countryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    graphData: PropTypes.array.isRequired,
    upcoming: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      getMilestonesGraphRequest: PropTypes.func.isRequired,
      getUpcomingMilestonesRequest: PropTypes.func.isRequired,
      getIndicatorDataRequest: PropTypes.func.isRequired,
      getResponsiblePartiesRequest: PropTypes.func.isRequired,
      setIsFilter: PropTypes.func.isRequired,
    }).isRequired,
    selectedPeriod: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    sectionId: PropTypes.string,
    indicatorId: PropTypes.string,
    page: PropTypes.string,
    needPrintPageBreak: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    customColumns: PropTypes.array.isRequired,
    responsibleParties: PropTypes.array.isRequired,
  }

  static defaultProps = {
    sectionId: undefined,
    indicatorId: undefined,
    page: '',
  }

  componentDidMount() {
    const {
      match,
      id,
      indicatorsGroupId,
      countryId,
      actions,
      sectionId,
      indicatorId,
    } = this.props;

    if (this.props.page === 'milestones') {
      scrollToElement(this.props.page);
    }

    const params = {
      sectionId: +match.params.sectionId || sectionId,
      indicatorId: id || indicatorId,
      indicatorsGroupId,
      countryId,
      projectId: match.params.projectId,
    };

    actions.getResponsiblePartiesRequest();
    actions.getMilestonesGraphRequest(params);
    actions.getUpcomingMilestonesRequest(params);
    actions.setIsFilter(true);
  }

  componentWillUnmount() {
    this.props.actions.setIsFilter(false);
  }

  onToggleChange = (value) => this.setState({ view: value });

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  getComparator = (order, orderBy) => {
    if (order === 'desc') {
      return (a, b) => this.descendingComparator(a, b, orderBy);
    }
    return (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  // TODO: move sort from frontend part to backend
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => ({
      ...el[0],
      responsibleParty: el[0].responsibleParty ? el[0].responsibleParty.name : '',
    }));
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleFiltersChange = (filters) => {
    const {
      match,
      id,
      indicatorsGroupId,
      countryId,
      actions,
      sectionId,
      indicatorId,
      projectId,
      selectedPeriod,
    } = this.props;

    const params = {
      sectionId: +match.params.sectionId || sectionId,
      indicatorId: id || indicatorId,
      indicatorsGroupId,
      countryId,
      projectId,
      selectedPeriod,
    };

    actions.getIndicatorDataRequest({
      ...params,
      ...filters,
      status: JSON.stringify(filters.status),
      customColumnsDataFilter: JSON.stringify(filters.customColumnsDataFilter),
    });
  }

  render() {
    const {
      milestones,
      title,
      graphData,
      upcoming,
      selectedPeriod,
      match,
      t,
      needPrintPageBreak,
      responsibleParties,
      isLoading,
      customColumns,
    } = this.props;

    // the customColumns table does not support localization; this lookup is a quick-fix / workaround for the issue.
    const lookups = {
      'Date de réunion': 'common.meeting_date',
      Participants: 'common.participants',
      'Date de début': 'common.start_date',
      Activité: 'common.milestones',
      Responsable: 'common.responsible_party',
      "Date d'achèvement estimée": 'common.estimated_completion_date',
      Observation: 'common.remarks',
    };

    const localizedCustomColumns = customColumns.map((c) => (
      { ...c, name: t(lookups[c.name] ? lookups[c.name] : c.name) }
    ));

    const {
      view,
      order,
      orderBy,
      rowsPerPage,
      page,
    } = this.state;

    const milestonesSorted = this.stableSort(milestones, this.getComparator(order, orderBy));
    const isMilestonePage = match.url.indexOf('/milestones') !== -1;

    return (
      <S.Container id="milestones" needPrintPageBreak={needPrintPageBreak}>
        {!isMilestonePage && (
          <S.MilestoneTitle>{t('common.milestones')}</S.MilestoneTitle>
        )}
        <S.TopRow>
          <S.TopRowItem>
            {/* <PieChart
              data={graphData}
              period={t(`country.filter.${selectedPeriod.period}`)}
              isMilestonePage={isMilestonePage}
            /> */}
            <FusionPieChart
              data={graphData}
              period={t(`country.filter.${selectedPeriod.period}`)}
              isMilestonePage={isMilestonePage}
            />
          </S.TopRowItem>
          <S.TopRowItem>
            <UpcomingMilestones data={upcoming} />
          </S.TopRowItem>
        </S.TopRow>
        <Filters
          responsibleParties={responsibleParties}
          onChange={this.handleFiltersChange}
          customColumns={localizedCustomColumns}
        />
        <ToggleButtonGroup
          value={view}
          onChange={this.onToggleChange}
          items={toggleViewItems}
        />
        <S.Content>
          {view === 'details' && (
            <>
              <S.StyledPaper>
                <S.TableWrapper>
                  <S.StyledTable aria-labelledby="tableTitle">
                    <Header
                      headRows={localizedCustomColumns}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <Body
                      numberRows={localizedCustomColumns.length}
                      rows={milestonesSorted.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)}
                      isLoading={isLoading}
                    />
                  </S.StyledTable>
                </S.TableWrapper>
              </S.StyledPaper>
              <Pagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={milestones.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </>
          )}
          {view === 'timeline' && (
            <Timeline milestones={milestones} indicatorTitle={title} />
          )}
        </S.Content>
      </S.Container>
    );
  }
}

const mapStateToProps = ({
  indicatorDetails: {
    indicatorData: {
      id,
      indicatorsGroupId,
      title,
      indicatorsMilestones: milestones,
      customColumns,
    },
    isLoading,
    selector,
  },
  country: {
    country: {
      id: countryId,
    },
    selectedProject: {
      id: projectId,
    },
  },
  milestones: {
    graphData,
    upcoming,
    responsibleParties,
  },
}) => ({
  milestones,
  customColumns,
  title,
  id,
  indicatorsGroupId,
  responsibleParties,
  countryId,
  projectId,
  graphData,
  upcoming,
  selectedPeriod: selector,
  isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...milestonesActions,
    ...actionsIndicatorDetails,
  }, dispatch),
});

export default compose(
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(),
)(Milestones);
