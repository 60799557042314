export const styles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#a7a7a7',
      },
    ],
  },
  // {
  //   elementType: 'labels',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   elementType: 'labels.icon',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#616161',
  //     },
  //   ],
  // },
  // {
  //   elementType: 'labels.text.stroke',
  //   stylers: [
  //     {
  //       color: '#f5f5f5',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'administrative',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'administrative.land_parcel',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  // {
  //   featureType: 'administrative.neighborhood',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eef1f5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eef1f5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

export const blank = () => { };
