export const loginFields = [
  {
    inputType: 'text',
    label: 'Email',
    value: 'email',
    i18n: 'admin.email',
    initialValue: '',
  },
  {
    inputType: 'password',
    label: 'Password',
    value: 'password',
    i18n: 'admin.password',
    initialValue: '',
  },
];

export const blank = () => {};
