export const forgotPasswordFields = [
  {
    inputType: 'text',
    label: 'Email',
    value: 'email',
    initialValue: '',
  },
];

export const blank = () => {};
