import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import FormCheckbox from 'components/FormCheckbox';
import FormSelect from 'components/FormSelect';
import {
  isProjectManagerOn, isProjectAdminOn, isCountryAdminOn, isCountryManagerOn, isCountryViewerOn,
} from 'helpers/roles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ExpandIcon from './ExpandIcon';
import * as S from './styled';
import { isSuperAdmin } from '../../../../../../helpers/roles';

const CountryRow = ({
  country,
  values,
  onHasOngingAccessCheckboxChanged,
  onCountryChange,
  onProjectChange,
  onProjectPermissionChanged,
  onCountryPermissionChanged,
  onHasOngingAccessChanged,
  isDisabled,
  selectedOnGoingAccess,
  selectedCountries,
  selectedProjects,
  currentUser,
  t,
}) => {
  const getOnGoingAccessIsChecked = (value, formValues) => selectedOnGoingAccess.includes(value);

  const getCountryIsChecked = (value, formValues) => selectedCountries.includes(value);

  const getProjectIsChecked = (value, formValues) => selectedProjects.includes(value);

  const getCountryRole = (countryId, formValues) => (formValues.permissions.countries[countryId] ? formValues.permissions.countries[countryId] : 'no access');

  const getProjectRole = (projectId, formValues) => (formValues.permissions.projects[projectId] ? formValues.permissions.projects[projectId] : 'no access');

  const getNoAccessIsDisabled = (permissions, country) => {
    const contryProjectsIds = country.projects.map((p) => p.id);

    return Object.keys(permissions.projects).some((id) => contryProjectsIds.includes(parseInt(id)));
  };

  const getProjectIsDisabled = (countryId, formValues) => formValues.permissions.countries[countryId] == 'admin';

  const getOnGoingAccessRole = (countryId, formValues) => (formValues.onGoingAccessCountries[countryId] ? formValues.onGoingAccessCountries[countryId] : 'no access');

  return (
    <div>
      <S.Row style={{ border: '1px solid #dde3ec', borderBottom: 'unset' }}>
        <S.CountryRow>
          <FormCheckbox
            field={{
              inputType: 'checkbox',
              name: 'countries',
              label: country.name,
              value: country.id,
              className: 'country-row',
            }}

            onChange={() => onCountryChange(country.id)}
            // disabled={isEditingCurrentUser || !allowedCountriesIds.includes(country.id)}
            disabled={isDisabled}
            checked={getCountryIsChecked(country.id, values)}
          />
        </S.CountryRow>
        <div>
          <S.Select
            value={getCountryRole(country.id, values)}
            name="role"
            onChange={(e) => {
              onCountryPermissionChanged(country.id, e.target.value);
            }}
          >
            {/* IF he can managed a project in a country AND he's a viewer of that country => he can not change the role of user's country */}
            <option disabled={(!isSuperAdmin(currentUser) && isCountryViewerOn(currentUser, country.id)) || getNoAccessIsDisabled(values.permissions, country)} value="no access">No Access</option>
            <option disabled={!isCountryAdminOn(currentUser, country.id)} value="admin">Admin</option>
            <option disabled={!isCountryManagerOn(currentUser, country.id) && !isCountryAdminOn(currentUser, country.id)} value="manager">Contributor</option>
            <option disabled={!isSuperAdmin(currentUser) && isCountryViewerOn(currentUser, country.id)} value="viewer">Viewer</option>
          </S.Select>
        </div>
      </S.Row>

      <S.Div>
        {(isSuperAdmin(currentUser) || !isCountryViewerOn(currentUser, country.id))
          && (
          <S.Row style={{
            background: '#f6f6f6', color: '#757575', paddingLeft: '3rem', paddingTop: '0.2rem', paddingBottom: '0.2rem',
          }}
          >
            <FormControlLabel
              control={(
                <Checkbox
                  value={country.id}
                  checked={getOnGoingAccessIsChecked(country.id, values)}
                  onChange={() => onHasOngingAccessCheckboxChanged(country.id)}
                />
              )}
              label={t('admin.on_going_access')}
            />

            <S.Select
              value={getOnGoingAccessRole(country.id, values)}
              disabled={getProjectIsDisabled(country.id, values)}
              onChange={(e) => {
                onHasOngingAccessChanged(country.id, e.target.value);
              }}
            >
              <option value="no access">No Access</option>
              {isCountryAdminOn(currentUser, country.id) && <option value="admin">Admin</option>}
              <option value="manager">Contributor</option>
              <option value="viewer">Viewer</option>
            </S.Select>
          </S.Row>
          )}

        {country.projects.filter((project) => currentUser.role == 'super_admin' || isProjectManagerOn(currentUser, project.id) || isProjectAdminOn(currentUser, project.id)).map((project) => (
          <S.Row style={{
            background: '#f6f6f6',
            color: '#757575',
            paddingLeft: '3rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            border: '1px solid #e8e8e8',
            borderBottom: 'unset',
          }}
          >
            <FormCheckbox
              field={{
                inputType: 'checkbox',
                name: 'projects',
                label: project.name,
                value: project.id,
              }}
              style={{ fontWeight: 'light' }}
              disabled={isDisabled}
              onChange={() => onProjectChange(project.id)}
              checked={getProjectIsChecked(project.id, values)}
            />

            <S.Select
              value={getProjectRole(project.id, values)}
              name="role"
              disabled={getProjectIsDisabled(country.id, values)}
              onChange={(e) => {
                onProjectPermissionChanged(project.id, e.target.value, country.id);
              }}
            >
              <option value="no access">No Access</option>
              <option value="admin" disabled={!isProjectAdminOn(currentUser, project.id)}>Admin</option>
              <option value="manager" disabled={!isProjectAdminOn(currentUser, project.id) && !isProjectManagerOn(currentUser, project.id)}>Contributor</option>
              <option value="viewer">Viewer</option>
            </S.Select>
          </S.Row>
        ))}
      </S.Div>
    </div>
  );
};

CountryRow.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  country: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onHasOngingAccessCheckboxChanged: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onProjectChange: PropTypes.func.isRequired,
  onProjectPermissionChanged: PropTypes.func.isRequired,
  onCountryPermissionChanged: PropTypes.func.isRequired,
  onHasOngingAccessChanged: PropTypes.func.isRequired,
  selectedOnGoingAccess: PropTypes.array.isRequired,
  selectedCountries: PropTypes.array.isRequired,
  selectedProjects: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CountryRow);
