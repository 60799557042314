import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withTranslation } from 'react-i18next';

import { downloadJson } from 'http/historyAPI';

import * as S from './styled';

const ActionButtons = ({
  rowId,
  t,
}) => (
  <>
    <S.Container>
      <Tooltip title={t('admin.history.download')}>
        <S.DownloadLink
          download
          href={downloadJson(rowId)}
        >
          <IconButton
            aria-label="Download"
          >
            <GetAppIcon color="primary" />
          </IconButton>
        </S.DownloadLink>
      </Tooltip>
    </S.Container>
  </>
);

ActionButtons.propTypes = {
  rowId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ActionButtons);
