import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import ActionButtons from './ActionButtons';

import * as S from './styled';

function applyCountryFilter(user, role, country) {
  const userProjectsWithCurrentRole = Object.keys(user.permissions.projects).filter((key) => user.permissions.projects[key] == role).map((c) => parseInt(c));
  const countryProjectsIds = country.projects.map((p) => p.id);
  const projectsWithRole = countryProjectsIds.filter((c) => userProjectsWithCurrentRole.includes(c));

  country.filterdProjects = country.projects.filter((p) => projectsWithRole.includes(p.id));

  const countriesWithRole = Object.keys(user.permissions.countries).filter((key) => user.permissions.countries[key] == role).map((c) => parseInt(c));

  return countriesWithRole.includes(country.id) || projectsWithRole.length;
}

function applyProjectFilter(user, role, project) {
  const userProjectsWithCurrentRole = Object.keys(user.permissions.projects).filter((key) => user.permissions.projects[key] == role).map((c) => parseInt(c));
  return userProjectsWithCurrentRole.includes(project.id);
}

const Body = ({
  rows,
  emptyRows,
  onEdit,
  onDelete,
  currentUser,
  filter,
  t,
}) => (
  <TableBody>
    {rows.map((row) => (
      <S.StyledRow
        hover
        tabIndex={-1}
        key={row.email}
      >
        <S.Cell component="th" scope="row" align="left">
          {row.firstName}
        </S.Cell>
        <S.Cell align="left">
          {row.lastName}
        </S.Cell>
        <S.Cell align="left">
          {row.email}
        </S.Cell>
        <S.Cell align="left">
          {row.position}
        </S.Cell>
        <S.Cell align="left">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {row.countries.filter((c) => applyCountryFilter(row, filter, c)).map((c) => (
              <div style={{ paddingRight: '2px' }}>
                <b>{c.name}:</b>
                {/* @todo: change this to only projects that belong to the current user. {row.projects} */}
                {c.projects.filter((p) => applyProjectFilter(row, filter, p)).map((p) => <span style={{ paddingRight: '2px' }}> {p.role} {p.name} </span>)}
              </div>
            ))}
          </div>

        </S.Cell>
        <S.StyledTableCellActions align="left">
          <ActionButtons
            user={row}
            currentUser={currentUser}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </S.StyledTableCellActions>
      </S.StyledRow>
    ))}
    {
      (emptyRows > 0 && rows.length) ? (
        <S.StyledRow style={{ height: 49 * emptyRows }}>
          <TableCell colSpan={7} />
        </S.StyledRow>
      ) : null
    }
    {
      !rows.length ? (
        <S.StyledRow style={{ height: 49 * emptyRows }}>
          <TableCell align="center" colSpan={7}>{t('admin.users_not_found')}</TableCell>
        </S.StyledRow>
      ) : null
    }
  </TableBody>
);

Body.propTypes = {
  rows: PropTypes.array.isRequired,
  emptyRows: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Body);
