import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

import Switch from '../Switch';

import * as S from './styled';

// the customColumns table does not support localization; this lookup is a quick-fix / workaround for the issue.
const lookups = {
  'Date de réunion': 'common.meeting_date',
  Participants: 'common.participants',
  'Date de début': 'common.start_date',
  Activité: 'common.milestones',
  Responsable: 'common.responsible_party',
  "Date d'achèvement estimée": 'common.estimated_completion_date',
  Observation: 'common.remarks',
};

class Body extends Component {
  state = {
    errorText: '',
  }

  onChange = (event, id) => {
    const { t } = this.props;

    if (event.target.value.length >= 40) {
      this.setState({ errorText: t('invalid.column_name_more_than_40_symbols'), id });
    } else {
      this.setState({ errorText: '', id: -1 });
    }
  }

  render() {
    const {
      customColumns,
      onOffsetPosition,
      onFilterableColumn,
      onColumnName,
      onDelete,
      t,
    } = this.props;

    return (
      <TableBody>
        {customColumns.map((column, index) => (
          <TableRow hover tabIndex={-1} key={column.id}>
            <S.Cell>
              {/* eslint-disable */}
              <S.StyledTextField
                label={t('admin.column_name')}
                defaultValue={t(lookups[column.name] ? lookups[column.name] : column.name)}
                helperText={column.id === this.state.id && this.state.errorText}
                onBlur={(event) => onColumnName(event.target.value, column.id)}
                onChange={(e) => this.onChange(e, column.id)}
                inputProps={{
                  maxLength: 40,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </S.Cell>
            <S.Cell>
              <Switch
                onClick={() => onFilterableColumn(column.id)}
                checked={column.isFilterable}
                disabled={column.isSystem}
              />
            </S.Cell>
            <S.Cell>
              <S.Order>
                <S.DropDown
                  onClick={() => onOffsetPosition(column.id, index + 1)}
                  disabled={index === (customColumns.length - 1)}
                >
                  <S.ArrowDropDown />
                </S.DropDown>
                <S.DropUp
                  onClick={() => onOffsetPosition(column.id, index - 1)}
                  disabled={index === 0}
                >
                  <S.ArrowDropUp />
                </S.DropUp>
              </S.Order>
            </S.Cell>
            <S.Cell>
              <IconButton
                onClick={() => onDelete(column.id)}
                disabled={column.isSystem}
              >
                <DeleteIcon color="inherit" />
              </IconButton>
            </S.Cell>
          </TableRow>
        ))}
      </TableBody>
    );
  }
}

Body.propTypes = {
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  customColumns: PropTypes.array.isRequired,
  onOffsetPosition: PropTypes.func.isRequired,
  onFilterableColumn: PropTypes.func.isRequired,
  onColumnName: PropTypes.func.isRequired,
};

export default withTranslation()(Body);
