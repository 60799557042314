import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const Container = styled(Flex).attrs(() => ({
  justifyContent: 'center',
}))`

`;

export const DownloadLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
