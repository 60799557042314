import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isCountryAdminOn, isCountryManagerOn } from 'helpers/roles';
import * as S from './styled';

const EmptyStateProjects = ({
  currentUser, slug, country, t,
}) => (
  <S.Container>
    <S.Title>There’s no projects or priorities visible here.</S.Title>
    <S.TextContainer>
      {(isCountryManagerOn(currentUser, country.id) || isCountryAdminOn(currentUser, country.id)) && <S.Text>If you’re a contributor or admin for this country you are able to create a new project and a new priority.</S.Text>}
      <S.Text>If you know of an existing project in this country that you would like to access, you will need to ask an admin or contributor of that project to give you access.</S.Text>
    </S.TextContainer>
    {(isCountryManagerOn(currentUser, country.id) || isCountryAdminOn(currentUser, country.id)) && <S.Button to={`/admin/country/${slug}/projects`}>Add new project</S.Button>}
  </S.Container>
);

EmptyStateProjects.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EmptyStateProjects);
