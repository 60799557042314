export const resetPasswordFields = [
  {
    inputType: 'password',
    label: 'Password',
    value: 'password',
    initialValue: '',
  },
  {
    inputType: 'password',
    label: 'Confirm password',
    value: 'confirmPassword',
    initialValue: '',
  },
];

export const blank = () => {};
