import { call, takeEvery, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import * as httpHistoryApi from 'http/historyAPI';

import { actions as types } from './index';

function* onGetHistoryApi({ payload }) {
  try {
    const params = {
      ...payload,
      type: payload.type === 'all' ? undefined : payload.type,
      status: payload.status === 'all' ? undefined : payload.status,
    };
    delete params.isOpened;

    const data = yield call(httpHistoryApi.getHistoryAPI, params);
    yield put(types.getHistoryApiSuccess(data));
  } catch (error) {
    Sentry.captureException(error);
    yield put(types.getHistoryApiFailure(error));
  }
}

const historyApiSagas = [
  takeEvery(types.getHistoryApiRequest, onGetHistoryApi),
];

export default historyApiSagas;
