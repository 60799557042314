import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import {
  showedUserRoleMap, filterRoles, isProjectManagerOn, isProjectAdminOn, isSuperAdmin, isCountryViewerOn, isCountryAdminOn, isCountryManagerOn,
} from 'helpers/roles';
import FormField from 'components/FormField';
import FormSelect from 'components/FormSelect';
import FormCheckbox from 'components/FormCheckbox';
import CountryRow from './CountryRow';
import GlobalVisibilityRow from './GlobalVisibilityRow';

import { validate } from './validation';

import * as S from './styled';

const Selectables = {
  'all-countries-and-projets': 'All countries and projects',
  'all-countries': 'All countries',
  'all-projects': 'All projects',
  none: 'None',
};

const UserForm = ({
  isFormDisabled,
  onSubmit,
  initialValues,
  isEditing,
  onClose,
  isEditingCurrentUser,
  allowedCountriesIds,
  countries,
  userRole,
  currentUser,
  t,
}) => {
  const [selectedSelectable, setSelectedSelectable] = useState(Object.keys(Selectables)[0]);
  const [selectedItemsRole, setSelectedItemsRole] = useState('no access');
  const [selectedOnGoingAccess, setSelectedOnGoingAccess] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [localInitialValues, setLocalInitialValues] = useState({
    ...initialValues,
    countries: initialValues.countries ? initialValues.countries.map((item) => item.id) : [],
    projects: initialValues.projects ? initialValues.projects.map((item) => item.id) : [],
  });

  const allCountiesIds = countries.map((c) => c.id);
  const allProjectsIds = countries.flatMap((c) => c.projects.map((p) => p.id));

  const handleHasOngingAccessCheckboxChanged = (countryId) => {
    selectedOnGoingAccess.includes(countryId) ? setSelectedOnGoingAccess(selectedOnGoingAccess.filter((p) => p !== countryId)) : setSelectedOnGoingAccess([...selectedOnGoingAccess, countryId]);
  };

  const handleCountryChange = (countryId) => {
    selectedCountries.includes(countryId) ? setSelectedCountries(selectedCountries.filter((c) => c !== countryId)) : setSelectedCountries([...selectedCountries, countryId]);
  };

  const handleProjectChange = (projectId) => {
    selectedProjects.includes(projectId) ? setSelectedProjects(selectedProjects.filter((p) => p !== projectId)) : setSelectedProjects([...selectedProjects, projectId]);
  };

  const handleCountryPermissionChanged = (countryId, role, values, form) => {
    const { permissions, onGoingAccessCountries } = values;

    const copiedPermissions = JSON.parse(JSON.stringify(permissions));

    if (role == 'no access') {
      delete copiedPermissions.countries[countryId];
    } else {
      copiedPermissions.countries[countryId] = role;
    }

    // If you are an admin of a country this automatically means you’re an admin of all projects in that country.
    if (role == 'admin') {
      const country = countries.filter((c) => c.id == countryId)[0];
      const projectIds = country.projects.map((p) => p.id);
      projectIds.map((id) => copiedPermissions.projects[id] = 'admin');

      onGoingAccessCountries[countryId] = 'admin';
      form.mutators.setFieldValue({ name: 'onGoingAccessCountries', value: onGoingAccessCountries });
    }

    form.mutators.setFieldValue({ name: 'permissions', value: copiedPermissions });
  };

  const handleProjectPermissionChanged = (projectId, role, values, form, countryId) => {
    const { permissions } = values;

    if (role == 'no access') {
      delete permissions.projects[projectId];
    } else {
      permissions.projects[projectId] = role;
    }

    if (!permissions.countries[countryId]) {
      permissions.countries[countryId] = 'viewer';
    }

    form.mutators.setFieldValue({ name: 'permissions', value: permissions });
  };

  const handleVisibilityChange = (role, values, form) => {
    role = role == 'no access' ? null : role;
    form.mutators.setFieldValue({ name: 'hasGlobalVisibility', value: role });
  };

  const handleSelectedItemsRoleChange = (e) => {
    const role = e.target.value;
    setSelectedItemsRole(role);
  };

  const handleSelectedSelectableChange = (e) => {
    const role = e.target.value;
    setSelectedSelectable(role);
  };

  const handleHasOngingAccessChanged = (countryId, role, values, form) => {
    const { onGoingAccessCountries, permissions } = values;
    if (role == 'no access') {
      delete onGoingAccessCountries[countryId];
    } else {
      onGoingAccessCountries[countryId] = role;
    }

    if (!permissions.countries[countryId]) {
      permissions.countries[countryId] = 'viewer';
    }

    // make all current projects same as the selected role
    countries.filter((c) => c.id == countryId)[0].projects.map((p) => {
      permissions.projects[p.id] = role;
    });

    form.mutators.setFieldValue({ name: 'onGoingAccessCountries', value: onGoingAccessCountries });
    form.mutators.setFieldValue({ name: 'permissions', value: permissions });
  };

  const changeSelectedItemsRole = (formMutators, values) => {
    const role = selectedItemsRole;
    const { permissions, onGoingAccessCountries } = values;
    const copiedPermissions = JSON.parse(JSON.stringify(permissions));

    selectedCountries.filter((countryId) => {
      if (role == 'admin') {
        return isCountryAdminOn(currentUser, countryId);
      }

      return !isSuperAdmin(currentUser) && !isCountryViewerOn(currentUser, countryId);
    }).map((countryId) => {
      if (role == 'no access') {
        delete copiedPermissions.countries[countryId];
      } else {
        copiedPermissions.countries[countryId] = role;
      }

      // If you are an admin of a country this automatically means you’re an admin of all projects in that country. Being an admin of country means
      if (role == 'admin') {
        const country = countries.filter((c) => c.id == countryId)[0];
        const projectIds = country.projects.map((p) => p.id);
        projectIds.map((id) => copiedPermissions.projects[id] = 'admin');
      }
    });

    selectedProjects.map((projectId) => {
      if (role == 'no access') {
        delete copiedPermissions.projects[projectId];
      } else {
        copiedPermissions.projects[projectId] = role;
        const countryId = countries.filter((c) => c.projects.filter((p) => p.id == projectId).length)[0].id;

        if (!copiedPermissions.countries[countryId]) {
          copiedPermissions.countries[countryId] = 'viewer';
        }
      }
    });

    selectedOnGoingAccess.map((countryId) => {
      onGoingAccessCountries[countryId] = role;
    });

    // If you are an admin of a country this automatically means you’re an admin of all projects in that country.
    if (role == 'admin') {
      selectedCountries.map((countryId) => {
        const country = countries.filter((c) => c.id == countryId)[0];
        const projectIds = country.projects.map((p) => p.id);
        projectIds.map((id) => permissions.projects[id] = 'admin');
      });
    }
    formMutators.setFieldValue({ name: 'onGoingAccessCountries', value: onGoingAccessCountries });
    formMutators.setFieldValue({ name: 'permissions', value: copiedPermissions });
  };

  const applySelect = (formMutators, values) => {
    const selectable = selectedSelectable;
    setSelectedProjects([]);
    setSelectedCountries([]);
    setSelectedOnGoingAccess([]);
    const projectsIds = countries.flatMap((c) => c.projects.map((p) => p.id));
    const countriesIds = countries.map((c) => c.id);

    if (selectable == 'all-countries-and-projets') {
      setSelectedProjects(projectsIds);
      setSelectedCountries(countriesIds);
      setSelectedOnGoingAccess(countriesIds); // (Selecting all projects would include the all current and new projects option)
    }

    if (selectable == 'all-countries') {
      setSelectedCountries(countriesIds);
    }

    if (selectable == 'all-projects') {
      setSelectedProjects(projectsIds);
      setSelectedOnGoingAccess(countriesIds); // (Selecting all projects would include the all current and new projects option)
    }
  };

  const onIsSuperAdminChange = (values, form) => {
    const value = values.role == 'super_admin' ? null : 'super_admin';

    form.mutators.setFieldValue({ name: 'role', value });
  };

  const getIsSuperAdminChecked = (values) => values.role == 'super_admin';

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => validate(values, allCountiesIds)}
      initialValues={localInitialValues}
      mutators={{
        ...arrayMutators,
        setFieldTouched,
        setFieldValue: ([args], state, utils) => {
          utils.changeValue(state, args.name, () => args.value);
        },
      }}
      render={({
        handleSubmit, hasValidationErrors, errors, values, dirty, form,
      }) => (
        <S.FormContentContainer onSubmit={handleSubmit}>
          <FormField
            field={{
              inputType: 'text',
              label: t('admin.email'),
              value: 'email',
            }}
            disabled={isEditing}
          />
          <S.Row>
            <FormField
              field={{
                inputType: 'text',
                label: t('admin.first_name'),
                value: 'firstName',
              }}
            />
            <FormField
              field={{
                inputType: 'text',
                label: t('admin.last_name'),
                value: 'lastName',
              }}
            />
          </S.Row>
          <S.Row>
            <FormField
              field={{
                inputType: 'text',
                label: t('admin.position'),
                value: 'position',
              }}
            />
            {/* Add is super admin checkbox, appears only for Super Admins */}
            {isSuperAdmin(currentUser) && (
              <S.Div style={{
                display: 'flex',
                minWidth: '48%',
                marginTop: '-13px',
              }}
              >
                <FormCheckbox
                  field={{
                    inputType: 'checkbox',
                    name: 'role',
                    label: 'Super Admin',
                  }}
                  style={{ fontWeight: 'light' }}
                  onChange={() => onIsSuperAdminChange(values, form)}
                  checked={getIsSuperAdminChecked(values)}
                />
              </S.Div>
            )}
          </S.Row>

          {/* Countries and Projects */}

          <div style={{ display: values.role == 'super_admin' ? 'none' : 'block' }}>
            <S.Row>
              <S.Label style={{ color: '#27323e' }}>
                {t('admin.country_and_project_access')}
                <p style={{
                  color: '#757575',
                  margin: '2px 0px',
                  fontSize: '14px',
                  fontWeight: 'lighter',
                }}
                >{t('admin.country_and_project_access_tagline')}
                </p>
              </S.Label>
            </S.Row>

            <div style={{ maxHeight: '400px', overflowY: 'scroll', paddingTop: '1rem' }}>
              {
                isSuperAdmin(currentUser)
                && (
                  <GlobalVisibilityRow
                    values={values}
                    onVisibilityChange={(role) => handleVisibilityChange(role, values, form)}
                  />
                )
              }

              {countries.map((country, index) => (
                <CountryRow
                  key={index}
                  country={country}
                  values={values}
                  onProjectPermissionChanged={(projectId, role, countryId) => handleProjectPermissionChanged(projectId, role, values, form, countryId)}
                  onCountryPermissionChanged={(countryId, role) => handleCountryPermissionChanged(countryId, role, values, form)}

                  onHasOngingAccessChanged={(countryId, role) => handleHasOngingAccessChanged(countryId, role, values, form)}

                  onHasOngingAccessCheckboxChanged={(countryId) => handleHasOngingAccessCheckboxChanged(countryId)}
                  onCountryChange={(countryId) => handleCountryChange(countryId)}
                  onProjectChange={(projectId) => handleProjectChange(projectId)}

                  selectedOnGoingAccess={selectedOnGoingAccess}
                  selectedCountries={selectedCountries}
                  selectedProjects={selectedProjects}
                  currentUser={currentUser}
                />
              ))}
            </div>

            <div style={{ background: '#eef1f5', padding: '4px' }}>
              <S.Div style={{ padding: '0.5rem', display: 'flex' }}>
                <S.Select
                  value={selectedSelectable}
                  onChange={handleSelectedSelectableChange}
                  style={{ marginRight: '1rem', fontSize: '13px' }}
                >
                  {Object.keys(Selectables).map((s) => <option value={s}>{Selectables[s]}</option>)}
                </S.Select>
                <S.StyledButton color="primary" onClick={() => applySelect(form.mutators, values)}>
                  Select
                </S.StyledButton>
              </S.Div>

              <S.Div style={{ padding: '0.5rem', display: 'flex', borderTop: '1px solid #dde3ec' }}>
                <span style={{ fontSize: '14px', lineHeight: '22px', marginRight: '1rem' }}>Change selected items to:</span>
                <S.Select
                  value={selectedItemsRole}
                  onChange={handleSelectedItemsRoleChange}
                  style={{ marginRight: '1rem', fontSize: '13px' }}
                >
                  <option value="no access">No Access</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Contributor</option>
                  <option value="viewer">Viewer</option>
                </S.Select>

                <S.StyledButton color="primary" onClick={() => changeSelectedItemsRole(form.mutators, values)}>
                  Apply
                </S.StyledButton>
              </S.Div>
            </div>
            {
              errors.permissions
              && (
                <S.Row style={{ padding: '1rem 0', color: '#757575' }}>
                  {t('admin.one_country_requirement')}
                </S.Row>
              )
            }
          </div>
          <DialogActions style={{ paddingTop: '1.5rem', justifyContent: 'flex-start' }}>
            <S.StyledButton
              disabled={
              (values.role == 'super_admin' && hasValidationErrors && !errors.permissions)
              || (values.role !== 'super_admin' && hasValidationErrors)
              || isFormDisabled
            }
              type="submit"
              style={{ height: '32px', fontSize: '16px' }}
            >
              {t('common.save')}
            </S.StyledButton>
            <Button
              color="secondary"
              onClick={onClose}
              style={{
                height: '32px', background: '#e8e8e8', color: 'rgba(38, 50, 63, 0.5)', textTransform: 'inherit', fontSize: '16px',
              }}
            > {t('common.close')}
            </Button>
          </DialogActions>

          {/* <p>
            dirty{JSON.stringify(initialValues.permissions)}
          </p> */}
        </S.FormContentContainer>
      )}
    />
  );
};

UserForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isEditingCurrentUser: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  allowedCountriesIds: PropTypes.array.isRequired,
  userRole: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UserForm);
