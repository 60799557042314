import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import { trimLongText } from 'helpers/string';

const TrimmedText = ({ text, length }) => {
  if (text && text.length > length) {
    return (
      <Tooltip title={text}>
        <div>{trimLongText(text, length)}</div>
      </Tooltip>
    );
  }

  return text;
};

TrimmedText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number,
};

TrimmedText.defaultProps = {
  length: 100,
};

export default TrimmedText;
