import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export const Root = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.div`
  /* margin-top: auto; */
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

export const GFWButton = styled(Button)`
  && {
    @media print {
      display: none;
    }
  }
`;

export const StyledSelect = styled.div`
&& {
  .MuiFormLabel-root {
    padding-bottom: 10px;
    font-size: 15px;
  }

  .MuiSelect-select {
    background: #fbfcfc;
    border: 1px solid #dde3ed;
    border-radius: 4px;
    height: 32px;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .MuiSelect-icon {
    color: rgb(38, 50, 63);
  }

  .MuiInput-underline:before {
    display: none;
  }
}

`;

export const ActionButton = styled(Link)`
  color: white;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 4px;
  height: 32px;
  padding: 0rem 0.75rem;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  line-height: 32px;
  text-decoration: none;
`;

export const ActionsContainer = styled.div`
  padding-left: 2rem;
  height: 32px;
  align-self: flex-end;
  a {
    margin-right: 1rem;
  }
`;
